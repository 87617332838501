// MateriasActivasInput.js
import React, { useState } from "react";
import { TextField, Button, Grid } from "@mui/material";

const MateriasActivasInput = ({ onAddMateriaActiva }) => {
  const [materiaActiva, setMateriaActiva] = useState("");

  const handleInputChange = (e) => {
    setMateriaActiva(e.target.value);
  };

  const handleAddMateriaActiva = () => {
    // Verificar si la materia activa es un número entero de 5 dígitos
    if (/^\d{5}$/.test(materiaActiva)) {
      onAddMateriaActiva(materiaActiva);
      setMateriaActiva("");
    } else {
      alert("La materia activa debe ser un número entero de 5 dígitos");
    }
  };
  

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={8}>
        <TextField
          fullWidth
          label="Materia Activa"
          value={materiaActiva}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={4}>
        <Button variant="outlined" color="primary" onClick={handleAddMateriaActiva}>
          Añadir
        </Button>
      </Grid>
    </Grid>
  );
};

export default MateriasActivasInput;

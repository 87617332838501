import React, { useState, useEffect,useCallback } from "react";
import { useParams } from 'react-router-dom';
import { api } from "../../login/OAuth";

import {
    Grid,
    TextField,
    Button,
    Autocomplete,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    Paper,
    Typography,
    Modal
} from "@mui/material";

function PastoreoEditAnimales() {
    const { id } = useParams();
    const [animalModalOpen, setAnimalModalOpen] = useState(false);
    const [animales, setAnimales] = useState([]);
    const [animalSeleccionado, setAnimalSeleccionado] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pastoreo, setPastoreo] = useState(null);
    const [especies, setEspecies] = useState([]);
    const [especieSeleccionada, setEspecieSeleccionada] = useState(null);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        especie: '',
        rega: '',
        numero: 0,
    });

    const fetchPastoreo = useCallback(async () => {
        try {
            const result = await api().get(`/pastoreos/${id}`);
            setPastoreo(result.data);
        } catch (error) {
            console.error("Error fetching pastoreos:", error);
            setError("Error al cargar el pastoreos");
        }
    },[id]);

    const fetchAnimales = async () => {
        try {
            const result = await api().get("/animales");
            setAnimales(result.data);
        } catch (error) {
            console.error("Error fetching animales:", error);
            setError("Error al cargar animales");
        }
    };

    useEffect(() => {
        const fetchEspecies = async () => {
            try {
                const result = await api().get("/catalogos/especies_animales");
                setEspecies(result.data);
            } catch (error) {
                console.error("Error fetching especies:", error);
                setError("Error al cargar especies");
            }
        };

        fetchAnimales();
        fetchPastoreo();
        fetchEspecies();
    }, [fetchPastoreo]);


    const handleAnimalChange = (_, newValue) => {
        setAnimalSeleccionado(newValue);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleEspecie = (event, newValue) => {
        if (newValue) {
            setEspecieSeleccionada(newValue);
            setFormData({ ...formData, especie: newValue.codigo_siex });

        }
    };

    const createAnimal = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const result = await api().post('/pastoreos/animal/new', formData);
            if (result.status < 300) {
                alert('Animal creado correctamente');
                fetchAnimales();
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        } finally {
            setLoading(false);
            setAnimalModalOpen(false);
        }
    };

    const addAnimal = async () => {
        try {
            setLoading(true);

            if (!animalSeleccionado) {
                setError("Selecciona un animal antes de añadirlo.");
                return;
            }

            const result = await api().post(`/pastoreos/${id}/animal/${animalSeleccionado.id}/add`);

            if (result.status < 300) {
                if (result.data && result.data.status_code >= 300) {
                    setError(result.data.detail);
                } else {
                    setError(null);
                    fetchPastoreo();
                    setAnimalSeleccionado(null);
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        } finally {
            setLoading(false);
        }
    };

    const deleteAnimal = async (animalID) => {
        try {
            setLoading(true);

            const result = await api().delete(`/pastoreos/${id}/animal/${animalID}/remove`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    const updatedPastoreo = await api().get(`/pastoreos/${id}`);
                    setPastoreo(updatedPastoreo.data);
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        } finally {
            setLoading(false);
        }
    };

    const openAnimalModal = () => {
        setAnimalModalOpen(true);
    };
    const closeAnimalModal = () => {
        setAnimalModalOpen(false);
    };

    return (
        <Grid container spacing={3}>
            {pastoreo && (<Grid item xs={12}>
                <Typography variant="h6" gutterBottom>Animales Asociados</Typography>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Especie</TableCell>
                                <TableCell>REGA</TableCell>
                                <TableCell>Numero</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pastoreo.animales.map((animal) => (
                                <TableRow key={animal.id}>
                                    <TableCell>{animal.especie}</TableCell>
                                    <TableCell>{animal.rega}</TableCell>
                                    <TableCell>{animal.numero}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            disabled={loading}
                                            onClick={() => deleteAnimal(animal.id)}

                                        >
                                            Eliminar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Grid>)}

            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>Añadir Animal</Typography>

                <Autocomplete
                    options={animales}
                    getOptionLabel={(option) => `Numero: ${option.numero || ""} // Especie: ${option.especie || ""} // REGA: ${option.rega || ""}`}
                    value={animalSeleccionado}
                    onChange={handleAnimalChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Animal"
                            fullWidth
                        />
                    )}
                /><br />
                <Button variant="contained" color="success" onClick={addAnimal} disabled={loading} style={{ marginRight: '10px' }}>
                    Añadir animal
                </Button>
                <Button variant="contained" color="primary" onClick={openAnimalModal} style={{ marginLeft: '10px' }}>
                    Crear nuevo animal
                </Button>

                {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
            </Grid>

            <Modal open={animalModalOpen} onClose={closeAnimalModal}>
                <Paper elevation={3} style={{ padding: 20, maxWidth: 400, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>

                    <Grid item xs={12}>
                        <h2>
                            Crear Animal
                        </h2>
                        <form onSubmit={createAnimal}>
                            <Autocomplete
                                options={especies}
                                getOptionLabel={(option) => option.especie?.toString()}
                                value={especieSeleccionada}
                                onChange={handleEspecie}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Especie"
                                        fullWidth
                                    />
                                )}
                            />
                            <TextField
                                label="Rega"
                                type="text"
                                name="rega"
                                value={formData.rega}
                                onChange={handleInputChange}
                                fullWidth
                                helperText="Ejemplo: 12345678901234"
                                required
                                margin="normal"
                            />
                            <TextField
                                label="Número"
                                type="number"
                                name="numero"
                                value={formData.numero}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                margin="normal"
                            />
                            <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                                Crear Animal
                            </Button>
                        </form>
                        {error && (
                            <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                {error}
                            </Typography>
                        )}
                    </Grid>
                </Paper>
            </Modal>
            
        </Grid>

    );

}

export default PastoreoEditAnimales;

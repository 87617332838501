import React, { useState, useEffect, useCallback } from "react";
import { useParams } from 'react-router-dom';
import { api } from "../../login/OAuth";

import { TextField, Button, Autocomplete, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";


const TratamPostCosechaEditProductoFito = () => {
    const { id } = useParams();
    const [tratam, setTratam] = useState(null);
    const [unidades, setUnidades] = useState(null);
    const [selectedUnidad, setSelectedUnidad] = useState(null);
    const [productosFito, setProductosFito] = useState(null);
    const [selectedProductoFito, setSelectedProductoFito] = useState(null);
    const [error, setError] = useState(null);

    const [productoData, setProductoData] = useState({
        num_registro: "",
        dosis: "",
        cantidad: "",
        unidad: "",
    });
    const [selectedField, setSelectedField] = useState("dosis");
    
    const fetchTratam = useCallback(async () => {
            try {
                const result = await api().get(`/tratamientos_post_cosecha/${id}`);
                setTratam(result.data);
    
                const resultUni = await api().get(`/catalogos/unidades`);
                setUnidades(resultUni.data);
    
                const resultPF = await api().get(`/catalogos/productos_fito`);
                setProductosFito(resultPF.data);
    
                if (result.data.producto_fito) {
                    setProductoData(result.data.producto_fito);
                    setSelectedField(result.data.producto_fito.dosis ? "dosis" : "cantidad");
    
                    const selectedProductoFito = resultPF.data.find(producto => parseInt(producto.numero_registro) === parseInt(result.data.producto_fito.num_registro));
                    if (selectedProductoFito) {
                        setSelectedProductoFito(selectedProductoFito);
                    } else {
                        console.error("Error: Producto fito no encontrado");
                    }
    
                    const selectedUnidad = resultUni.data.find(unidad => unidad.codigo_siex === result.data.producto_fito.unidad);
                    if (selectedUnidad) {
                        setSelectedUnidad(selectedUnidad);
                    } else {
                        console.error("Error: Unidad no encontrada");
                    }
                
                }
            } catch (error) {
                console.error("Error fetching tratamiento:", error);
            }
        },[id]);

    useEffect(() => {
        fetchTratam();
    }, [fetchTratam]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductoData({ ...productoData, [name]: value });
    };

    const handleFieldChange = (e) => {
        setSelectedField(e.target.value);
        if (e.target.value === "dosis") {
            setProductoData({ ...productoData, dosis: 0 });
        } else {
            setProductoData({ ...productoData, cantidad: 0 });
        }
    };
    const handleUnidad = (event, newValue) => {
        if (newValue) {
            setSelectedUnidad(newValue);
            setProductoData({ ...productoData, unidad: newValue.codigo_siex });
        }
    };
    const handleProductoFito = (event, newValue) => {
        if (newValue) {
            setSelectedProductoFito(newValue);
            setProductoData({ ...productoData, num_registro: newValue.numero_registro });
        }
    };

    const handleEditProducto = async () => {
        try {
            const fieldToUpdate = selectedField === "dosis" ? "dosis" : "cantidad";
            const dataToUpdate = {
                num_registro: productoData.num_registro,
                unidad: productoData.unidad,
            };

            if (productoData[fieldToUpdate] !== "") {
                dataToUpdate[fieldToUpdate] = productoData[fieldToUpdate];
            }
            console.log(dataToUpdate)
            const result = await api().patch(`/tratamiento_post/${id}/producto_fito/update`, dataToUpdate);

            if (result.status < 300) {
                alert("Producto fito actualizado correctamente");
                fetchTratam();
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        }
    };

    return (
        <div>
            {tratam && tratam.producto_fito ? (
                <>
                                    <Typography variant="h6" gutterBottom>Detalles del Producto Fito</Typography>

                    <FormControl fullWidth>
                        <InputLabel id="select-field-label">Seleccionar Campo</InputLabel>
                        <Select
                            labelId="select-field-label"
                            id="select-field"
                            value={selectedField}
                            onChange={handleFieldChange}
                        >
                            <MenuItem value="dosis">Dosis</MenuItem>
                            <MenuItem value="cantidad">Cantidad</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        name={selectedField}
                        value={productoData[selectedField]}
                        onChange={handleInputChange}
                        required
                        margin="normal"
                    />
                    <Autocomplete
                        options={productosFito}
                        getOptionLabel={(option) => option.numero_registro?.toString() + "   " + option.nombre?.toString() || ""}
                        value={selectedProductoFito}
                        onChange={handleProductoFito}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Producto fitosanitario"
                                fullWidth
                                required
                                margin="normal"

                            />
                        )}
                    />
                    <Autocomplete
                        options={unidades}
                        getOptionLabel={(option) => option.codigo_siex?.toString() + "   " + option.unidades_medida?.toString() || ""}
                        value={selectedUnidad}
                        onChange={handleUnidad}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Unidad"
                                fullWidth
                                required
                                margin="normal"

                            />
                        )}
                    />
                    <br />
                    <br />
                    <Button type="button" variant="contained" color="primary" onClick={handleEditProducto}>
                        Editar Producto
                    </Button>
                </>
            ) : (
                <>cargando</>
            )}
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    );
};

export default TratamPostCosechaEditProductoFito;

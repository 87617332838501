import React, { useState, useEffect,useCallback } from "react";
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { api } from '../login/OAuth';
import { Container, Box, Autocomplete, TextField, Typography, CircularProgress, List, ListItem, ListItemText } from "@mui/material";


function DatosExplotacion() {
    const location = useLocation();
    const cuaderno = location.state && location.state.cuaderno;
    const [medidas, setMedidas] = useState([]);
    const [medidaSeleccionada, setMedidaSeleccionada] = useState(null);
    const [loading, setLoading] = useState(false);
    const [datos_explotacion, setDatosExplotacion] = useState(null);
    const [error, setError] = useState(null);


    const fetchData = useCallback(async () => {
        try {
            const result = await api().get(`cuadernos/${cuaderno.id_cuaderno}/datos_explotacion/`)
            setDatosExplotacion(result.data)
        } catch (error) {
            console.error("Error fetching medidas:", error);
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        }
    },[cuaderno.id_cuaderno]);

    useEffect(() => {

        const fetchMedidas = async () => {
            try {
                const result = await api().get("/catalogos/medidas");
                setMedidas(result.data);
            } catch (error) {
                console.error("Error fetching medidas:", error);
                setError(`Error: ${JSON.parse(error.request.response).detail}`);
            }
        };
        fetchData()
        fetchMedidas()
    }, [fetchData]);

    const eliminarMedida = async (id) => {
        try {
            setLoading(true);
            const result = await api().delete(`/cuaderno/${cuaderno.id_cuaderno}/medida_preventiva/${id}/remove`);
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);
                } else {
                    fetchData();
                    setError(null);
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            console.error("Error eliminando medida:", error);
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
        console.log(`Eliminar medida preventiva con ID: ${id}`);
    };


    const createMedida = async (event) => {
        event.preventDefault();
        try {
            if (medidaSeleccionada != null) {
                setLoading(true);
                const result = await api().post(`/cuaderno/${cuaderno.id_cuaderno}/medida_preventiva/add?medida=${medidaSeleccionada.id_medida_prevent_cultural}`,);

                if (result.status < 300) {
                    if (result.data != null && result.data.status_code >= 300) {
                        setError(`Error ${result.status}: ${result.data.detail}`);
                    } else {
                        fetchData();
                        setMedidaSeleccionada(null)
                        setError(null);

                    }
                } else {
                    setError(`Error ${result.status}: ${result.data.detail}`);
                }

            } else {
                setError("Seleccione una medida preventiva");
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    }


    const handleMedidaChange = (_, newValue) => {
        setMedidaSeleccionada(newValue);
    };

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box sx={{ marginTop: 12, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5">
                    Medidas preventivas
                </Typography>
                <Box component="form" noValidate onSubmit={createMedida} sx={{ mt: 3, width: '80%' }}>
                    <Autocomplete
                        options={medidas}
                        getOptionLabel={(option) => `${option.id_medida_prevent_cultural || ""} ${option.medida || ""}`}
                        value={medidaSeleccionada}
                        onChange={handleMedidaChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Medida"
                                fullWidth
                            />
                        )}
                    />
                    <Button
                        toLink="/"
                        type="submit"
                        fullWidth
                        color="success"
                        variant="contained"
                        disabled={loading}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {loading ? <CircularProgress size={24} /> : "Añadir nueva medida"}
                    </Button>
                </Box>
            </Box>

            {datos_explotacion && datos_explotacion.medida_preventiva_cultural ? (
                <List sx={{ width: '100%', mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Medidas Preventivas:
                    </Typography>
                    {Object.entries(datos_explotacion.medida_preventiva_cultural).map(([key, value]) => (
                        <ListItem key={value.id_medida_prevent_cultural}>
                            <ListItemText
                                primary={`Medida ${value.id_medida_prevent_cultural}: ${value.medida}`}
                            />
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => eliminarMedida(value.id_medida_prevent_cultural)}
                            >
                                X
                            </Button>
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Typography variant="body1" sx={{ width: '80%', textAlign: 'center' }}>
                    Sin medidas preventivas
                </Typography>
            )}
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </Container>
    );
}

export default DatosExplotacion
import React, { useState, useEffect,useCallback } from "react";
import { useParams } from 'react-router-dom';
import { api } from "../../login/OAuth";

import {
    Grid,
    TextField,
    Button,
    Autocomplete,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    Paper,
    Typography
} from "@mui/material";

function PastoreoEditDetAct() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [pastoreo, setPastoreo] = useState(null);
    const [detalles, setDetalles] = useState([]);
    const [detalleSeleccionado, setDetalleSeleccionado] = useState(null);
    const [error, setError] = useState(null);


    const fetchPastoreo = useCallback(async () => {
        try {
            const result = await api().get(`/pastoreos/${id}`);
            setPastoreo(result.data);
        } catch (error) {
            console.error("Error fetching pastoreos:", error);
            setError("Error al cargar el pastoreos");
        }
    },[id]);

    useEffect(() => {

        const fetchDetalle = async () => {
            try {
                const result = await api().get("/catalogos/detalles_act_agraria");
                setDetalles(result.data);
            } catch (error) {
                console.error("Error fetching detalles:", error);
                setError("Error al cargar detalles");
            }
        };

        fetchDetalle();
        fetchPastoreo();
    }, [fetchPastoreo]);

    const handleDetalleChange = (_, newValue) => {
        setDetalleSeleccionado(newValue);
    };

    const addDetalle = async () => {
        try {
            setLoading(true);

            if (!detalleSeleccionado) {
                setError("Selecciona un detalle antes de añadirlo.");
                return;
            }

            const result = await api().post(`/pastoreos/${id}/detalles_act_agraria/${detalleSeleccionado.codigo_detalle}/add`);

            if (result.status < 300) {
                if (result.data && result.data.status_code >= 300) {
                    setError(result.data.detail);
                } else {
                    setError(null);
                    fetchPastoreo();

                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };

    const deleteDetalle = async (animalID) => {
        try {
            setLoading(true);

            const result = await api().delete(`/pastoreos/${id}/detalles_act_agraria/${animalID}/remove`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    const updatedPastoreo = await api().get(`/pastoreos/${id}`);
                    setPastoreo(updatedPastoreo.data);
                    setError(null);
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid container spacing={3}>
            {pastoreo && (

                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>Añadir detalle</Typography>

                        <Autocomplete
                            options={detalles}
                            getOptionLabel={(option) => `Ambito: ${option.ambito || ""} // Actvidad Agraria: ${option.actividad_agraria || ""}// Detalle: ${option.detalle_actividad_agraria || ""}`}
                            value={detalleSeleccionado}
                            onChange={handleDetalleChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Detalle"
                                    fullWidth
                                />
                            )}
                        />
                        <br />
                        <Button variant="contained" color="success" onClick={addDetalle} disabled={loading}>
                            Añadir detalle
                        </Button>
                    </Grid>
                    <Typography variant="h6" gutterBottom>Detalles Asociados</Typography>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Codigo Detalle</TableCell>
                                    <TableCell>Ambito</TableCell>
                                    <TableCell>Detalle</TableCell>
                                    <TableCell>Actividad Agraria</TableCell>
                                    <TableCell>Observaciones</TableCell>
                                    <TableCell>Eliminar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pastoreo.detalles_actividad_agrarias.map((detalle) => (
                                    <TableRow key={detalle.id}>
                                        <TableCell>{detalle.codigo_detalle}</TableCell>
                                        <TableCell>{detalle.ambito}</TableCell>
                                        <TableCell>{detalle.detalle_actividad_agraria}</TableCell>
                                        <TableCell>{detalle.actividad_agraria}</TableCell>
                                        <TableCell>{detalle.observaciones}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                disabled={loading}
                                                onClick={() => deleteDetalle(detalle.codigo_detalle)}

                                            >
                                                Eliminar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {error && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {error}
                        </Typography>
                    )}
                </Grid>)}
        </Grid>
    );

}

export default PastoreoEditDetAct
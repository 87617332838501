import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { api } from "../login/OAuth"

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    Typography
} from "@mui/material";


function ListarCuadernos() {
    const [cuadernos, setCuadernos] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchCuadernos();
    }, []);


    const fetchCuadernos = async () => {
        try {
            const result = await api().get("/cuadernos/list");
            setCuadernos(result.data);
        } catch (error) {
            console.error("Error fetching cuadernos:", error);
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        }
    };

    const handleDeleteItem = async () => {
        try {
            console.log(deleteItemId)
            const result = await api().delete(`/cuadernos/${deleteItemId}/delete`)
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    fetchCuadernos();
                    handleCloseDeleteDialog();
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        }

    };

    const handleOpenDeleteDialog = (itemId) => {
        setDeleteItemId(itemId);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                                            <Typography variant="h6" gutterBottom>Cuaderno ID</Typography>

                        </TableCell>
                        <TableCell>
                                            <Typography variant="h6" gutterBottom>Titular ID</Typography>

                        </TableCell>
                        <TableCell>
                                            <Typography variant="h6" gutterBottom>Detalles</Typography>

                        </TableCell>
                        <TableCell>
                                            <Typography variant="h6" gutterBottom>Exportar</Typography>

                        </TableCell>
                        <TableCell>
                                            <Typography variant="h6" gutterBottom>Eliminar</Typography>

                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cuadernos.map(cuaderno => (
                        <TableRow key={cuaderno.id}>
                            <TableCell component="th" scope="row">
                                {cuaderno.id_cuaderno}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {cuaderno.id_titular}
                            </TableCell>
                            <TableCell>
                                <Link to={`/cuadernos/${cuaderno.id_cuaderno}`} state={{ cuaderno }}>
                                <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                                        Detalles
                                    </Button>
                                </Link>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <Link to={`/cuadernos/${cuaderno.id_cuaderno}/export`}>
                                    <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                                        Exportar cuaderno
                                    </Button>
                                </Link>
                            </TableCell>
                            <TableCell>
                                <Button onClick={() => handleOpenDeleteDialog(cuaderno.id)} variant="contained" color="error">
                                    Eliminar
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}

                    <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                        <DialogTitle>¿Estás seguro de eliminar este tratamiento post cosecha?</DialogTitle>
                        <DialogActions>
                            <Button onClick={handleCloseDeleteDialog} color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={handleDeleteItem} color="error">
                                Eliminar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </TableBody>
            </Table>
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </TableContainer>
    )
}
export default ListarCuadernos;
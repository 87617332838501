import React, { useState } from "react";
import { Button, Container, Box, Typography , CircularProgress} from "@mui/material";
import { api } from '../login/OAuth';

function ResetProductos() {
    const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);

            const result = await api().post('/reset/productos');

            if (result.status === 200) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Productos reseteado correctamente");
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <Container>
            <Box mt={3}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Resetear los productos
                </Typography>
                <Typography variant="body1" paragraph>
                    Al pulsar este botón se resetearán los productos.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : "Resetear Ahora"}
                </Button>
            </Box>
        </Container>
    );
}

export default ResetProductos;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { api } from "../../login/OAuth";
import { TextField, Button, Typography, Modal, Input, InputLabel, Grid, Paper, Autocomplete } from '@mui/material';

function CreatePastoreoForm() {
    const { id } = useParams();
    const [animalModalOpen, setAnimalModalOpen] = useState(false);
    const [animales, setAnimales] = useState([]);
    const [animalesSeleccionados, setAnimalesSeleccionados] = useState([]);
    const [dgcs, setDGCs] = useState([]);
    const [dgcsSeleccionadas, setDgcsSeleccionadas] = useState([]);
    const [detalles, setDetalles] = useState([]);
    const [detalleSeleccionados, setDetalleSeleccionados] = useState([]);
    const [especies, setEspecies] = useState([]);
    const [especieSeleccionada, setEspecieSeleccionada] = useState(null);
    const [formData, setFormData] = useState({
        animales_ids: [],
        dgcs_ids: [],
        pastoreo_data: {
            id_ajena_pastoreo: 0,
            fecha_inicio: '',
            fecha_fin: '',
        },
        detalles_act_agraria_codigos: [],
    });
    const [animalData, setAnimalData] = useState({
        especie: '',
        rega: '',
        numero: 0,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {

        const fetchDGCs = async () => {
            try {
                const result = await api().get("/dgcs");
                setDGCs(result.data);
            } catch (error) {
                console.error("Error fetching justificaciones:", error);
            }
        };
        const fetchDetalle = async () => {
            try {
                const result = await api().get("/catalogos/detalles_act_agraria");
                setDetalles(result.data);
            } catch (error) {
                console.error("Error fetching detalles:", error);
                setError("Error al cargar detalles");
            }
        };
        const fetchEspecies = async () => {
            try {
                const result = await api().get("/catalogos/especies_animales");
                setEspecies(result.data);
            } catch (error) {
                console.error("Error fetching especies:", error);
                setError("Error al cargar especies");
            }
        };

        fetchDetalle();
        fetchDGCs();
        fetchAnimales();
        fetchEspecies();


    }, []);

    const fetchAnimales = async () => {
        try {
            const result = await api().get("/animales");
            setAnimales(result.data);
        } catch (error) {
            console.error("Error fetching animales:", error);
            setError("Error al cargar animales");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleAnimalChange = (_, newValue) => {
        const animalIds = newValue.map(animal => animal.id);
        setFormData({ ...formData, animales_ids: animalIds });
        setAnimalesSeleccionados(newValue);
    };

    const handleDGCChange = (_, newValue) => {
        const dgcIDs = newValue.map(dgc => dgc.id);
        setFormData({ ...formData, dgcs_ids: dgcIDs });
        setDgcsSeleccionadas(newValue);
    };
    const handleDetallesChange = (_, newValue) => {
        const detallesCodigos = newValue.map(detalle => detalle.codigo_detalle);
        setFormData({ ...formData, detalles_act_agraria_codigos: detallesCodigos });
        setDetalleSeleccionados(newValue);
    };
    const handleEspecie = (event, newValue) => {
        if (newValue) {
            setEspecieSeleccionada(newValue);
            setAnimalData({ ...animalData, especie: newValue.codigo_siex });
        }
    };
    const handleAnimalDataChange = (e) => {
        const { name, value } = e.target;
        setAnimalData({ ...animalData, [name]: value });
    };


    const createAnimal = async (e) => {
        e.preventDefault();
        try {
            const result = await api().post('/pastoreos/animal/new', animalData);
            if (result.status < 300) {
                alert('Animal creado correctamente');
                fetchAnimales();
                setError(null);
                setAnimalModalOpen(false);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        } 
            
            finally {
            setLoading(false);
        }
    };

    const openAnimalModal = () => {
        setAnimalModalOpen(true);
    };
    const closeAnimalModal = () => {
        setAnimalModalOpen(false);
    };

    const createPastoreo = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const result = await api().post(`/cuadernos/${id}/pastoreo/new`, {
                cuaderno_id: parseInt(id),
                pastoreo_data: {
                    fecha_inicio: formData.fecha_inicio,
                    fecha_fin: formData.fecha_fin,
                    id_ajena_pastoreo: formData.id_ajena_pastoreo
                },
                animales_ids: formData.animales_ids,
                dgcs_ids: formData.dgcs_ids,
                detalles_act_agraria_codigos: formData.detalles_act_agraria_codigos
            });
            if (result.status < 300) {
                alert('Pastoreo creado correctamente');
                navigate(`/cuadernos/${id}/pastoreo`);
                setError(null);

            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid>
            <Typography variant="h5" gutterBottom>
                Crear Pastoreo
            </Typography>
            <form onSubmit={createPastoreo}>

                <TextField
                    label="ID Ajena Pastoreo"
                    type="number"
                    name="id_ajena_pastoreo"
                    value={formData.id_ajena_pastoreo}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    margin="normal"
                    style={{ marginBottom: '10px' }}
                />
                <InputLabel htmlFor="fecha-inicio">Fecha Inicio</InputLabel>
                <Input
                    id="fecha-inicio"
                    type="date"
                    name="fecha_inicio"
                    value={formData.fecha_inicio}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    margin="normal"
                    style={{ marginBottom: '10px' }}
                />
                <br></br>
                <InputLabel htmlFor="fecha-fin">Fecha Fin</InputLabel>
                <Input
                    id="fecha-fin"
                    type="date"
                    name="fecha_fin"
                    value={formData.fecha_fin}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    style={{ marginBottom: '10px' }}
                />
                <Autocomplete
                    multiple
                    options={animales}
                    getOptionLabel={(option) => `ID: ${option.id} // Numero: ${option.numero || ""} // Especie: ${option.especie || ""} // REGA: ${option.rega || ""}`}
                    value={animalesSeleccionados}
                    onChange={handleAnimalChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Animales"
                            fullWidth
                            style={{ marginBottom: '10px' }}

                        />
                    )}
                />
                <Autocomplete
                    multiple
                    options={dgcs}
                    getOptionLabel={(option) => `ID: ${option.id}`}
                    value={dgcsSeleccionadas}
                    onChange={handleDGCChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="DGCs"
                            fullWidth
                            style={{ marginBottom: '10px' }}
                        />
                    )}
                />
                <Autocomplete
                    multiple
                    options={detalles}
                    getOptionLabel={(option) => `Ambito: ${option.ambito || ""} // Actvidad Agraria: ${option.actividad_agraria || ""}`}
                    value={detalleSeleccionados}
                    onChange={handleDetallesChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Detalle"
                            fullWidth
                        />
                    )}
                />
                <br></br>

                <Button variant="outlined" color="primary" onClick={openAnimalModal}>
                    Crear nuevo animal
                </Button>
                <br></br>


                <br></br>
                <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                    Crear Pastoreo
                </Button>
            </form>
            <Modal open={animalModalOpen} onClose={closeAnimalModal}>
                <Paper elevation={3} style={{ padding: 20, maxWidth: 400, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>

                    <Grid item xs={12}>
                        <h2>
                            Crear Animal
                        </h2>
                        <form onSubmit={createAnimal}>
                            <Autocomplete
                                options={especies}
                                getOptionLabel={(option) => option.especie?.toString()}
                                value={especieSeleccionada}
                                onChange={handleEspecie}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Especie"
                                        fullWidth
                                    />
                                )}
                            />
                            <TextField
                                label="Rega"
                                type="text"
                                name="rega"
                                value={animalData.rega}
                                onChange={handleAnimalDataChange}
                                fullWidth
                                helperText="Ejemplo: 12345678901234"
                                required
                                margin="normal"
                            />
                            <TextField
                                label="Número"
                                type="number"
                                name="numero"
                                value={animalData.numero}
                                onChange={handleAnimalDataChange}
                                fullWidth
                                required
                                margin="normal"
                            />
                            <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                                Crear Animal
                            </Button>
                        </form>
                        {error && (
                            <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                {error}
                            </Typography>
                        )}
                    </Grid>
                </Paper>
            </Modal>
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </Grid>
    );
}

export default CreatePastoreoForm;

import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';

export const SidebarData = [{
  title: 'Home',
  path: '/',
  icon: <AiIcons.AiFillHome />,
  cName: 'nav-text',
  allowedRol: ['All']
},
{
  title: 'Cuaderno',
  path: '#',
  icon: <AiIcons.AiFillAlipayCircle />,
  cName: 'nav-text',
  allowedRol: ['All'],
  subNav: [
    {
      title: 'Crear',
      allowedRol: ['All'],
      path: '/cuadernos/new',
      icon: <FaIcons.FaFutbol />,
    },
    {
      title: 'Listar cuadernos',
      allowedRol: ['All'],
      path: '/cuadernos/list',
      icon: <FaIcons.FaFutbol />,
    },{
      title: 'Producto-cultivos',
      allowedRol: ['All'],
      path: '/productos_fito/producto-cultivo/',
      icon: <FaIcons.FaFutbol />,
    }
  ]
},
{
  title: 'Migrate',
  path: '#',
  icon: <IoIcons.IoMdPeople />,
  cName: 'nav-text',
  allowedRol: ['All'],
  subNav: [
    {
      title: 'Productos',
      allowedRol: ['All'],
      path: '/migrate/productos',
      icon: <FaIcons.FaFutbol />,
    },
    {
      title: 'Sigpac',
      allowedRol: ['All'],
      path: '/migrate/sigpac',
      icon: <FaIcons.FaFutbol />,
    },
    {
      title: 'Catálogos cue',
      allowedRol: ['All'],
      path: '/migrate/catalogos',
      icon: <FaIcons.FaFutbol />,
    },
  ]
  },
  {
  title: 'Reset',
  path: '#',
  icon: <IoIcons.IoMdPeople />,
  cName: 'nav-text',
  allowedRol: ['All'],
  subNav: [
    {
      title: 'Productos',
      allowedRol: ['All'],
      path: '/reset/productos',
      icon: <FaIcons.FaFutbol />,
    },
    {
      title: 'Sigpac',
      allowedRol: ['All'],
      path: '/reset/sigpac',
      icon: <FaIcons.FaFutbol />,
    },
    {
      title: 'Registros',
      allowedRol: ['All'],
      path: '/reset/registros',
      icon: <FaIcons.FaFutbol />,
    },
  ]
  },
  {
    title: 'Registros',
    path: '/registros/list',
    icon: <AiIcons.AiOutlineFolderOpen />,
    cName: 'nav-text',
    allowedRol: ['All']
  },
];

import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { api } from "../../login/OAuth"
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";


function Pastoreo() {
    const [pastoreos, setPastoreos] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        api()
            .get(`/cuadernos/${id}/pastoreos`)
            .then((response) => {
                if (response.data.status_code) {
                } else {
                    setPastoreos(response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [id]);

    const handleOpenDeleteDialog = (itemId) => {
        setDeleteItemId(itemId);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteItem = () => {
        api().delete(`/pastoreos/${deleteItemId}/delete`)
            .then((response) => {
                console.log('Elemento eliminado con éxito');
                setPastoreos((prevTratams) => prevTratams.filter(tratam => tratam.id !== deleteItemId));
                handleCloseDeleteDialog();
            })
            .catch((error) => {
                console.error('Error al eliminar el elemento', error);
            });
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <br></br>
                <Link to={`/cuadernos/${id}/pastoreo/new`} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="primary">
                        Crear pastoreo
                    </Button>
                </Link>
            </Grid>

            {pastoreos.length > 0 && (
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>IDAjena Pastoreo</TableCell>
                                    <TableCell>Fecha Inicio</TableCell>
                                    <TableCell>Fecha Fin</TableCell>
                                    <TableCell>Animales</TableCell>
                                    <TableCell>DGCs</TableCell>
                                    <TableCell>Detalles Actividad Agraria</TableCell>
                                    <TableCell>Editar</TableCell>
                                    <TableCell>Eliminar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pastoreos.map((pastoreo) => (
                                    <TableRow key={pastoreo.id}>
                                        <TableCell>{pastoreo.id_ajena_pastoreo}</TableCell>
                                        <TableCell>{pastoreo.fecha_inicio}</TableCell>
                                        <TableCell>{pastoreo.fecha_fin}</TableCell>
                                        <TableCell>
                                            <Link to={`/pastoreo/${pastoreo.id}/edit/animales`} style={{ textDecoration: 'none' }}>
                                                <Button variant="contained" color="primary">
                                                    Editar
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/pastoreo/${pastoreo.id}/edit/dgcs`} style={{ textDecoration: 'none' }}>
                                                <Button variant="contained" color="primary">
                                                    Editar
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/pastoreo/${pastoreo.id}/edit/detalle_act`} style={{ textDecoration: 'none' }}>
                                                <Button variant="contained" color="primary">
                                                    Editar
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/pastoreo/${pastoreo.id}/edit/`} style={{ textDecoration: 'none' }}>
                                                <Button variant="contained" color="primary">
                                                    Editar
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleOpenDeleteDialog(pastoreo.id)} variant="contained" color="error">
                                                Eliminar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}

            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>¿Estás seguro de eliminar este tratamiento post cosecha?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteItem} color="error">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
export default Pastoreo;
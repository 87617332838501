import React, { useState, useEffect,useCallback } from "react";
import { useParams } from 'react-router-dom';
import { api } from "../../login/OAuth";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Autocomplete,
    TextField,
    Typography
} from "@mui/material";

function PastoreoEditDGCs() {
    const { id } = useParams();
    const [dgcs, setDGCs] = useState([]);
    const [dgc, setDGC] = useState();
    const [loading, setLoading] = useState(false);
    const [tratam, setTratam] = useState("");
    const [error, setError] = useState(null);

    const fetchTratam = useCallback(async () => {
        try {
            const result = await api().get(`/pastoreos/${id}`);
            setTratam(result.data);
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
            console.error("Error fetching pastoreo:", error);
        }
    },[id]);

    useEffect(() => {
        const fetchDGCs = async () => {
            try {
                const result = await api().get("/dgcs");
                setDGCs(result.data);
            } catch (error) {
                setError(`Error: ${JSON.parse(error.request.response).detail}`);
                console.error("Error fetching justificaciones:", error);
            }
        };
        fetchDGCs();
        fetchTratam();
    }, [id, fetchTratam]);

    const handleDGC = (event, newValue) => {
        if (newValue) {
            setDGC(newValue);
        }
    };

    const addDGC = async () => {
        try {
            setLoading(true);

            const result = await api().post(`/pastoreos/${id}/dgc/add?dgc_id=${dgc.id}`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error: ${JSON.parse(error.request.response).detail}`);
                } else {
                    fetchTratam();
                    setError(null);

                }
            } else {
                setError(`Error: ${JSON.parse(error.request.response).detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };
    const deleteDGC = async (DGCId) => {
        try {
            setLoading(true);

            const result = await api().delete(`/pastoreos/${id}/dgc/${DGCId}/remove`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error: ${JSON.parse(error.request.response).detail}`);
                } else {
                    fetchTratam();
                    setError(null);

                }
            } else {
                setError(`Error: ${JSON.parse(error.request.response).detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {tratam && (
                <>
                    <Typography variant="h6" gutterBottom>Añadir DGC</Typography>

                    <Autocomplete
                        options={dgcs}
                        getOptionLabel={(option) => '//ID: ' + option.id?.toString() + "   //Producto cosechado: " + option.producto_cosechado?.toString() + "   //Variedad: " + option.variedad?.toString() || ""}
                        value={dgc}
                        onChange={handleDGC}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="DGC"
                                fullWidth
                            />
                        )}
                    /><br />
                    <Button variant="contained" color="success" onClick={addDGC} disabled={loading}>
                        Añadir DGC
                    </Button>

                    {tratam.dgcs && (
                        <>
                            <Typography variant="h6" gutterBottom>DGCS Asociadas</Typography>

                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>Cultivo</TableCell>
                                            <TableCell>Variedad</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tratam.dgcs.map((dgc) => (
                                            <TableRow key={dgc.id}>
                                                <TableCell>{dgc.id}</TableCell>
                                                <TableCell>{dgc.producto_cosechado}</TableCell>
                                                <TableCell>{dgc.variedad}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => deleteDGC(dgc.id)}
                                                        disabled={loading}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}

                </>

            )}
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    );
}

export default PastoreoEditDGCs;

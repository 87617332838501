import React, { useState, useEffect , useCallback} from "react";
import { useParams } from 'react-router-dom';
import { api } from "../../login/OAuth";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Autocomplete,
    TextField,
    Typography
} from "@mui/material";

function TratamFitoEditProblematica() {
    const { id } = useParams();
    const [plagas, setPlagas] = useState([]);
    const [enfermedades, setEnfermedades] = useState([]);
    const [reguladores, setReguladores] = useState([]);
    const [malasHierbas, setMalasHierbas] = useState([]);
    const [tipoPlaga, setTipoPlaga] = useState();
    const [tipoEnfermedad, setTipoEnfermedad] = useState();
    const [tipoRegulador, setTipoRegulador] = useState();
    const [tipoMalaHierba, setTipoMalaHierba] = useState();
    const [loading, setLoading] = useState(false);
    const [tratam, setTratam] = useState("");
    const [error, setError] = useState(null);

    const fetchTratam = useCallback(async () => {
        try {
            const result = await api().get(`/tratam_fitos/${id}`);
            setTratam(result.data);
        } catch (error) {
            console.error("Error fetching tratamiento:", error);
        }
    },[id]);

    useEffect(() => {
        
        fetchPlagas();
        fetchReguladores();
        fetchEnfermedades();
        fetchTratam();
        fetchMalasHierbas();
    }, [id,fetchTratam]);
    
    const fetchPlagas = async () => {
        try {
            const result = await api().get("/catalogos/plagas");
            setPlagas(result.data);
        } catch (error) {
            console.error("Error fetching plagas:", error);
        }
    };
    const fetchEnfermedades = async () => {
        try {
            const result = await api().get("/catalogos/enfermedades");
            setEnfermedades(result.data);
        } catch (error) {
            console.error("Error fetching enfermedades:", error);
        }
    };
    const fetchReguladores = async () => {
        try {
            const result = await api().get("/catalogos/reguladores");
            setReguladores(result.data);
        } catch (error) {
            console.error("Error fetching reguladores:", error);
        }
    };
    const fetchMalasHierbas = async () => {
        try {
            const result = await api().get("/catalogos/malas_hierbas");
            setMalasHierbas(result.data);
        } catch (error) {
            console.error("Error fetching reguladores:", error);
        }
    };
    const handleTipoPlagaChange = (event, newValue) => {
        if (newValue) {
            setTipoPlaga(newValue);
        }
    };
    const handleTipoEnfermedadChange = (event, newValue) => {
        if (newValue) {
            setTipoEnfermedad(newValue);
        }
    };
    const handleTipoReguladorChange = (event, newValue) => {
        if (newValue) {
            setTipoRegulador(newValue);
        }
    };
    const handleTipoMalaHierbaChange = (event, newValue) => {
        if (newValue) {
            setTipoMalaHierba(newValue);
        }
    };
    const addPlaga = async () => {
        try {
            setLoading(true);

            const result = await api().post(`/tratam_fitos/${id}/plaga/add?tipo_plaga=${tipoPlaga.tipo_plaga}`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    fetchTratam();
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };
    const addEnfermedad = async () => {
        try {
            setLoading(true);

            const result = await api().post(`/tratam_fitos/${id}/enfermedad/add?tipo_enfermedad=${tipoEnfermedad.tipo_enfermedad}`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    fetchTratam();
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };
    const addRegulador = async () => {
        try {
            setLoading(true);

            const result = await api().post(`/tratam_fitos/${id}/regulador/add?tipo_regulador=${tipoRegulador.tipo_regulador}`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    fetchTratam();
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };
    const addMalaHierba = async () => {
        try {
            setLoading(true);

            const result = await api().post(`/tratam_fitos/${id}/malas_hierbas/add?mala_hierba_num_orden=${tipoMalaHierba.numero_orden}`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    fetchTratam();
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };
    const deletePlaga = async (plagaId) => {
        try {
            setLoading(true);

            const result = await api().delete(`/tratam_fitos/${id}/plaga/${plagaId}/remove`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    fetchTratam();
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };
    const deleteEnfermedad = async (enfermedadId) => {
        try {
            setLoading(true);

            const result = await api().delete(`/tratam_fitos/${id}/enfermedad/${enfermedadId}/remove`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    fetchTratam();
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };
    const deleteRegulador = async (reguladorId) => {
        try {
            setLoading(true);

            const result = await api().delete(`/tratam_fitos/${id}/regulador/${reguladorId}/remove`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    fetchTratam();
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };
    const deleteMalaHierba = async (malaHierbaId) => {
        try {
            setLoading(true);

            const result = await api().delete(`/tratam_fitos/${id}/malas_hierbas/${malaHierbaId}/remove`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    fetchTratam();
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div>
            {tratam && (
                <>
                    <Typography variant="h5" gutterBottom>Detalles del Tratamiento</Typography>

                    <Typography variant="h6" gutterBottom>Añadir Plaga</Typography>

                    <Autocomplete
                        options={plagas}
                        getOptionLabel={(option) =>   option.tipo_plaga?.toString() + "   " + option.nombre_cientifico?.toString() + "   " + option.categoria?.toString()|| ""}
                        value={tipoPlaga}
                        onChange={handleTipoPlagaChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Tipo de Plaga"
                                fullWidth
                            />
                        )}
                    />
                    <br />

                    <Button variant="contained" color="success" onClick={addPlaga} disabled={loading}>
                        Añadir Plaga
                    </Button>

                    {tratam.problematica_fito && tratam.problematica_fito.artropodos_gasteropodos && tratam.problematica_fito.artropodos_gasteropodos.length > 0 && (
                        <>
                                            <Typography variant="h6" gutterBottom>Plagas Asociadas</Typography>

                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Tipo de Plaga</TableCell>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Categoria</TableCell>
                                            <TableCell>Eliminar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tratam.problematica_fito.artropodos_gasteropodos.map((plaga) => (
                                            <TableRow key={plaga.tipo_plaga}>
                                                <TableCell>{plaga.tipo_plaga}</TableCell>
                                                <TableCell>{plaga.nombre_cientifico}</TableCell>
                                                <TableCell>{plaga.categoria}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => deletePlaga(plaga.tipo_plaga)}
                                                        disabled={loading}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                                    <Typography variant="h6" gutterBottom>Añadir Enfermedad</Typography>

                    <Autocomplete
                        options={enfermedades}
                        getOptionLabel={(option) =>  option.tipo_enfermedad?.toString() + "   " + option.nombre_cientifico?.toString() + "   " + option.categoria?.toString()|| ""}
                        value={tipoEnfermedad}
                        onChange={handleTipoEnfermedadChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Tipo de Enfermedad"
                                fullWidth
                            />
                        )}
                    />
                    <br />

                    <Button variant="contained" color="success" onClick={addEnfermedad} disabled={loading}>
                        Añadir Enfermedad
                    </Button>

                    {tratam.problematica_fito && tratam.problematica_fito.enfermedades && tratam.problematica_fito.enfermedades.length > 0 && (
                        <>
                                            <Typography variant="h6" gutterBottom>Enfermedades Asociadas</Typography>

                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Tipo de Enfermedad</TableCell>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Categoria</TableCell>
                                            <TableCell>Eliminar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tratam.problematica_fito.enfermedades.map((enfermedad) => (
                                            <TableRow key={enfermedad.tipo_enfermedad}>
                                                <TableCell>{enfermedad.tipo_enfermedad}</TableCell>
                                                <TableCell>{enfermedad.nombre_cientifico}</TableCell>
                                                <TableCell>{enfermedad.categoria}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => deleteEnfermedad(enfermedad.tipo_enfermedad)}
                                                        disabled={loading}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                                    <Typography variant="h6" gutterBottom>Añadir Regulador</Typography>

                    <Autocomplete
                        options={reguladores}
                        getOptionLabel={(option) =>  option.tipo_regulador?.toString() + "   " + option.nombre_cientifico?.toString() + "   " + option.categoria?.toString()|| ""}
                        value={tipoRegulador}
                        onChange={handleTipoReguladorChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Tipo de Regulador"
                                fullWidth
                            />
                        )}
                    />
                    <br />

                    <Button variant="contained" color="success" onClick={addRegulador} disabled={loading}>
                        Añadir Regulador
                    </Button>

                    {tratam.problematica_fito && tratam.problematica_fito.reguladores_otros && tratam.problematica_fito.reguladores_otros.length > 0 && (
                        <>
                                            <Typography variant="h6" gutterBottom>Regualadores Asociados</Typography>

                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Tipo de Regulador</TableCell>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Categoria</TableCell>
                                            <TableCell>Eliminar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tratam.problematica_fito.reguladores_otros.map((regulador) => (
                                            <TableRow key={regulador.tipo_regulador}>
                                                <TableCell>{regulador.tipo_regulador}</TableCell>
                                                <TableCell>{regulador.nombre_cientifico}</TableCell>
                                                <TableCell>{regulador.categoria}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => deleteRegulador(regulador.tipo_regulador)}
                                                        disabled={loading}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                                    <Typography variant="h6" gutterBottom>Añadir mala hierba</Typography>

                    <Autocomplete
                        options={malasHierbas}
                        getOptionLabel={(option) =>  option.numero_orden?.toString() + "   " + option.nombre_cientifico?.toString() + "   " + option.categoria?.toString() || ""}
                        value={tipoMalaHierba}
                        onChange={handleTipoMalaHierbaChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Tipo de mala hierba"
                                fullWidth
                            />
                        )}
                    />
                    <br />

                    <Button variant="contained" color="success" onClick={addMalaHierba} disabled={loading}>
                        Añadir mala hierba
                    </Button>

                    {tratam.problematica_fito && tratam.problematica_fito.malas_hierbas && tratam.problematica_fito.malas_hierbas.length > 0 && (
                        <>
                                            <Typography variant="h6" gutterBottom>Malas hierbas asociadas</Typography>

                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Num orden</TableCell>
                                            <TableCell>Nombre cientifico</TableCell>
                                            <TableCell>Categoria</TableCell>
                                            <TableCell>Eliminar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tratam.problematica_fito.malas_hierbas.map((mala_hierba) => (
                                            <TableRow key={mala_hierba.id}>
                                                <TableCell>{mala_hierba.numero_orden}</TableCell>
                                                <TableCell>{mala_hierba.nombre_cientifico}</TableCell>
                                                <TableCell>{mala_hierba.categoria}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => deleteMalaHierba(mala_hierba.numero_orden)}
                                                        disabled={loading}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                </>
                
            )}
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    );
}

export default TratamFitoEditProblematica;

import React, { useState, useEffect, useCallback } from "react";
import { useParams } from 'react-router-dom';
import { api } from "../../login/OAuth";

import {
    Table,
    Grid,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Autocomplete,
    TextField,
    Checkbox,
    FormControlLabel,
    Modal,
    Typography
} from "@mui/material";

function TratamFitoEditIdentAplicadores() {
    const { id } = useParams();
    const [identificadorModalOpen, setIdentificadorModalOpen] = useState(false);
    const [identificadores, setIdentificadores] = useState([]);
    const [identificadoresSeleccionados, setIdentificadoresSeleccionados] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tratam, setTratam] = useState(null);
    const [numeroRopo, setNumeroRopo] = useState('');
    const [aplicadorEmpresaId, setAplicadorEmpresaId] = useState("");
    const [equipoAplicadorId, setEquipoAplicadorId] = useState("");
    const [aplicadores, setAplicadores] = useState([]);
    const [equipos, setEquipos] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState("");

    const [error, setError] = useState(null);
    const [equipoValues, setEquipoValues] = useState({
        numeroRoma: null,
        numeroReganip: null,
        equipoPropio: false,
        idEquipoAplicador: null,
    });

    const fetchTratam =useCallback(async () => {
        try {
            const result = await api().get(`/tratam_fitos/${id}`);
            setTratam(result.data);
        } catch (error) {
            console.error("Error fetching tratamiento:", error);
            setError("Error al cargar el tratamiento");
        }
    },[id]);

    useEffect(() => {

        fetchIdentificadores();
        fetchTratam();
        fetchAplicadores();
        fetchEquipos();
    }, [id, fetchTratam]);

    const handleIdentificadorChange = (_, newValue) => {
        setIdentificadoresSeleccionados(newValue || []);
    };

    const fetchIdentificadores = async () => {
        try {
            const result = await api().get("/identificadores");
            setIdentificadores(result.data);
        } catch (error) {
            console.error("Error fetching identificadores:", error);
            setError("Error al cargar identificadores");
        }
    };

    const fetchAplicadores = async () => {
        try {
            const result = await api().get("/aplicadores");
            setAplicadores(result.data);
        } catch (error) {
            console.error("Error fetching aplicadores:", error);
            setError("Error al cargar aplicadores");
        }
    };

    const fetchEquipos = async () => {
        try {
            const result = await api().get("/equipos_aplicadores");
            setEquipos(result.data);
        } catch (error) {
            console.error("Error fetching equipos:", error);
            setError("Error al cargar equipos");
        }
    };

    const addIdentificador = async () => {
        try {
            setLoading(true);

            const idsSeleccionados = identificadoresSeleccionados.map(identificador => identificador.id);

            const result = await api().post(`/tratam_fitos/${id}/ident_aplicador/add`, idsSeleccionados);

            if (result.status < 300) {
                if (result.data && result.data.status_code >= 300) {
                    setError(result.data.detail);
                } else {
                    setError(null);
                    alert("Identificadores añadidos correctamente");
                    fetchTratam();
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        } finally {
            setLoading(false);
        }
    };

    const deleteIdentificador = async (identId) => {
        try {
            setLoading(true);

            const result = await api().delete(`/tratam_fitos/${id}/ident_aplicador/${identId}/remove`);

            if (result.status < 300) {
                if (result.data && result.data.status_code >= 300) {
                    setError(result.data.detail);
                } else {
                    setError(null);
                    alert("Identificador eliminado correctamente");
                    fetchTratam();
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        } finally {
            setLoading(false);
        }
    };

    const createAplicadorEmpresa = async (num_ropo) => {
        try {
            setLoading(true);

            const result = await api().post(`/aplicadores_empresa/new?num_ropo=${num_ropo}`);

            if (result.status < 300) {
                if (result.data && result.data.status_code >= 300) {
                    setError(result.data.detail);
                } else {
                    setError(null);
                    alert("AplicadorEmpresa creado correctamente");
                    fetchAplicadores();
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        } finally {
            setLoading(false);
        }
    };

    const createEquipoAplicador = async () => {
        try {
            setLoading(true);

            const result = await api().post('/equipos_aplicadores/new', {
                num_roma: equipoValues.numeroRoma,
                num_reganip: equipoValues.numeroReganip,
                equipo_propio: equipoValues.equipoPropio,
                id_equipo_aplicador: equipoValues.idEquipoAplicador
            });

            if (result.status < 300) {
                if (result.data && result.data.status_code >= 300) {
                    setError(result.data.detail);
                } else {
                    setError(null);
                    alert("EquipoAplicador creado correctamente");
                    fetchEquipos();
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        } finally {
            setLoading(false);
        }
    };


    const createIdentificadorEmpresa = async (aplicadorEmpresaId, equipoAplicadorId) => {
        try {
            setLoading(true);

            const url = `/identificadores_aplicadores/new?aplicador_empresa_id=${aplicadorEmpresaId}&equipo_aplicador_id=${equipoAplicadorId}`;

            const result = await api().post(url);

            if (result.status < 300) {
                if (result.data && result.data.status_code >= 300) {
                    setError(result.data.detail);
                } else {
                    setError(null);
                    alert("IdentificadorEmpresa creado correctamente");
                    fetchIdentificadores();
                    closeIdentificadorModal();
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        } finally {
            setLoading(false);
        }
    };

    const openIdentificadorModal = () => {
        setIdentificadorModalOpen(true);
    };
    const closeIdentificadorModal = () => {
        setIdentificadorModalOpen(false);
    };


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>Añadir Identificador</Typography>

                <Autocomplete
                    multiple
                    options={identificadores}
                    getOptionLabel={(option) => `Aplicador: ${option?.aplicador_empresa?.num_ropo || ""} // Equipo: ${option.equipo_aplicador_tratam?.id || ""}`}
                    value={identificadoresSeleccionados}
                    onChange={handleIdentificadorChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Identificadores"
                            fullWidth
                        />
                    )}
                /><br />
                <Button variant="contained" color="success" onClick={addIdentificador} disabled={loading}>
                    Añadir Identificadores
                </Button>
            </Grid>

            {tratam && (
                <>
                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>Identificadores Asociados</Typography>

                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Aplicador</TableCell>
                                        <TableCell>Equipo</TableCell>
                                        <TableCell>Eliminar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tratam.identificador_aplicadores.map((ident) => (
                                        <TableRow key={ident.id}>
                                            <TableCell>{ident.aplicador_empresa?.num_ropo}</TableCell>
                                            <TableCell>{ident.equipo_aplicador_tratam?.id}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => deleteIdentificador(ident.id)}
                                                    disabled={loading}
                                                >
                                                    Eliminar
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={openIdentificadorModal}>
                            Crear nuevo identificador
                        </Button>
                    </Grid>

                    <Modal open={identificadorModalOpen} onClose={closeIdentificadorModal}>
                        <Paper elevation={3} style={{ padding: 20, maxWidth: 400, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <h1>Crear Identificador</h1>
                            <Grid item xs={12}>
                                <h3>Crear Aplicador Empresa</h3>
                                <TextField
                                    label="Número Ropo"
                                    onChange={(e) => setNumeroRopo(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    required
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => createAplicadorEmpresa(numeroRopo)}
                                    disabled={loading}
                                >
                                    Crear Aplicador Empresa
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <h3>Crear Equipo Aplicador</h3>
                                <FormControl fullWidth>
                                    <InputLabel>Atributo</InputLabel>
                                    <Select
                                        label="Atributo"
                                        value={selectedAttribute}
                                        onChange={(e) => setSelectedAttribute(e.target.value)}
                                    >
                                        <MenuItem value="numeroRoma">Número Roma</MenuItem>
                                        <MenuItem value="numeroReganip">Número Reganip</MenuItem>
                                        <MenuItem value="idEquipoAplicador">ID Equipo Aplicador</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label={selectedAttribute === "idEquipoAplicador" ? "ID Equipo Aplicador" : `Número ${selectedAttribute}`}
                                    onChange={(e) => {
                                        let newValues = Object.keys(equipoValues).reduce((obj, key) => {
                                            obj[key] = null;
                                            return obj;
                                        }, {});

                                        newValues[selectedAttribute] = e.target.value;

                                        setEquipoValues(newValues);
                                    }}
                                    fullWidth
                                    required
                                    margin="normal"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={equipoValues.equipoPropio}
                                            onChange={(e) => setEquipoValues({ ...equipoValues, equipoPropio: e.target.checked })}
                                        />
                                    }
                                    label="Equipo Propio"
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={createEquipoAplicador}
                                    disabled={loading}
                                >
                                    Crear Equipo Aplicador
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <h3>Crear Identificador Empresa</h3>
                                <FormControl fullWidth>
                                    <InputLabel>Aplicador Empresa</InputLabel>
                                    <Select
                                        label="Aplicador Empresa"
                                        onChange={(e) => setAplicadorEmpresaId(e.target.value)}
                                    >
                                        {aplicadores.map((aplicador) => (
                                            <MenuItem key={aplicador.id} value={aplicador.id}>
                                                {aplicador.num_ropo}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel>Equipo Aplicador</InputLabel>
                                    <Select
                                        label="Equipo Aplicador"
                                        onChange={(e) => setEquipoAplicadorId(e.target.value)}
                                    >
                                        {equipos.map((equipo) => (
                                            <MenuItem key={equipo.id} value={equipo.id}>
                                            {equipo.num_reganip ? `Número REGANIP: ${equipo.num_reganip}` :
                                                equipo.num_roma ? `Número ROMA: ${equipo.num_roma}` :
                                                    `ID Aplicador: ${equipo.id_aplicador}`}
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => createIdentificadorEmpresa(aplicadorEmpresaId, equipoAplicadorId)}
                                    disabled={loading}
                                    margin="normal"
                                >
                                    Crear Identificador Empresa
                                </Button>
                            </Grid>
                        </Paper>
                    </Modal>
                </>
            )}

            {error && (
                <Grid item xs={12}>
                    <div style={{ color: 'red', marginTop: '10px' }}>
                        {error}
                    </div>
                </Grid>
            )}
        </Grid>
    );

}

export default TratamFitoEditIdentAplicadores;
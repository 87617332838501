import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { api } from "../../login/OAuth";
import ProductosFitoTable from "./ProductosFitoTable";
import MateriasActivasInput from "./MateriasActivasInput";
import DateTimePickerComponent from "../../../components/DateTimePickerComponent";

import {
    Button,
    TextField,
    Grid,
    Table,
    Paper,
    Box,
    FormControl,
    Select,
    MenuItem,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Autocomplete,
    Chip,
    Modal,
    Typography,
    Input
} from "@mui/material";

function TratamFitoNew() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [identificadorModalOpen, setIdentificadorModalOpen] = useState(false);
    const [productoModalOpen, setProductoModalOpen] = useState(false);

    const [numeroRopo, setNumeroRopo] = useState(null);
    const [selectedAttribute, setSelectedAttribute] = useState("");
    const [aplicadorEmpresaId, setAplicadorEmpresaId] = useState("");
    const [equipoAplicadorId, setEquipoAplicadorId] = useState("");
    const [aplicadores, setAplicadores] = useState([]);
    const [equipos, setEquipos] = useState([]);

    const [cultivos, setCultivos] = useState(null);
    const [selectedCultivo, setSelectedCultivo] = useState(null);

    const [cultivosNoComunes, setCultivosNoComunes] = useState([]);
    const [selectedCultivoNoComun, setSelectedCultivoNoComun] = useState(null);
    const [productoInfo, setProductoInfo] = useState(null);
    const [abierto, setAbierto] = useState(false);

    const [selectedUso, setSelectedUso] = useState(null);

    const [productos, setProductos] = useState([]);
    const [selectedProducto, setSelectedProducto] = useState(null);

    const [unidades, setUnidades] = useState(null);
    const [selectedUnidadOtraActuacion, setSelectedUnidadOtraActuacion] = useState(null);
    const [selectedUnidadProducto, setSelectedUnidadProducto] = useState(null);
    const [justificaciones, setJustificaciones] = useState(null);
    const [selectedJustificacion, setSelectedJustificacion] = useState(null);
    const [eficacias, setEficacias] = useState(null);
    const [selectedEficacia, setSelectedEficacia] = useState(null);
    const [tiposMedida, setTiposMedida] = useState(null);
    const [selectedTipoMedida, setSelectedTipoMedida] = useState(null);
    const [dgcs, setDGCs] = useState(null);
    const [selectedDGC, setSelectedDGC] = useState(null);
    const [identificadores, setIdentificadores] = useState(null);
    const [selectedIdentificador, setSelectedIdentificador] = useState(null);
    const [error, setError] = useState(null);

    const [selectedField, setSelectedField] = useState("dosis");

    const navigate = useNavigate();

    const fetchAplicadores = async () => {
        try {
            const result = await api().get("/aplicadores");
            setAplicadores(result.data);
        } catch (error) {
            console.error("Error fetching aplicadores:", error);
            alert("Error al cargar aplicadores");
        }
    };
    const fetchIdentificadores = async () => {
        try {
            const result = await api().get("/identificadores");
            setIdentificadores(result.data);
        } catch (error) {
            console.error("Error fetching identificadores:", error);
        }
    };
    const fetchEquipos = async () => {
        try {
            const result = await api().get("/equipos_aplicadores");
            setEquipos(result.data);
        } catch (error) {
            console.error("Error fetching equipos:", error);
            alert("Error al cargar equipos");
        }
    };
    const fetchProductosFito = async () => {
        try {
            const result = await api().get(`/productos_fito/${selectedCultivo.producto_siex}/num_registro`);
            if (result.data.length === 0) {
                setAbierto(true);
                setProductos([]);
                setSelectedProducto(null);

            } else {
                setSelectedProducto(null);
                setProductos(result.data);
                setAbierto(false);
            }

        } catch (error) {
            console.error("Error fetching productos_fito:", error);
        }
    };
    const fetchDetallesProductoUso = async () => {
        try {
            let selectedCultivoValue = selectedCultivoNoComun ? selectedCultivoNoComun : selectedCultivo.producto_siex;
            const result = await api().get(`/productos_fito/${selectedCultivoValue}/${selectedProducto.numero_registro}`);
            setProductoInfo(result.data);
            console.log(result)
        } catch (error) {
            console.error("Error fetching productos_fito:", error);
        }
    };
    const fetchProductosFitoNC = async () => {
        try {
            const result = await api().get(`/productos_fito/${selectedCultivoNoComun}/productos`);
            setProductos(result.data);

        } catch (error) {
            console.error("Error fetching productos_fito:", error);
        }
    };

    useEffect(() => {
        if (selectedCultivo) {
            setSelectedCultivoNoComun(null);
            fetchProductosFito();
            setProductoData({ ...productoData, tipo_producto: selectedCultivo.codigo });

        }
    }, [selectedCultivo]);

    useEffect(() => {
        if (selectedCultivoNoComun) {
            fetchProductosFitoNC();
        }
    }, [selectedCultivoNoComun]);

    useEffect(() => {
        if (selectedCultivo == null) {
            setAbierto(false);
            setProductos([]);
            setSelectedProducto(null);
            setProductoInfo(null);
            setSelectedCultivoNoComun(null);

        }
    }, [selectedCultivo]);

    useEffect(() => {
        if (selectedProducto) {
            fetchDetallesProductoUso();
            setProductoData({ ...productoData, num_registro: selectedProducto.numero_registro });
        }
    }, [selectedProducto]);

    useEffect(() => {
        if (!productoModalOpen) {
            setSelectedProducto(null);
            setSelectedCultivo(null);
            setSelectedCultivoNoComun(null);
        }
    }, [productoModalOpen]);

    useEffect(() => {

        const fetchEficacias = async () => {
            try {
                const result = await api().get(`/catalogos/eficacias`);
                setEficacias(result.data);

            } catch (error) {
                console.error("Error fetching eficacias:", error);
            }
        };
        const fetchUnidades = async () => {
            try {
                const result = await api().get(`/catalogos/unidades`);
                setUnidades(result.data);

            } catch (error) {
                console.error("Error fetching unidades:", error);
            }
        };
        const fetchTiposMedida = async () => {
            try {
                const result = await api().get(`/catalogos/tipos_medida_fito`);
                setTiposMedida(result.data);

            } catch (error) {
                console.error("Error fetching tipos_medida:", error);
            }
        };
        const fetchJustificaciones = async () => {
            try {
                const result = await api().get(`/catalogos/justificaciones`);
                setJustificaciones(result.data);

            } catch (error) {
                console.error("Error fetching justificaciones:", error);
            }
        };
        const fetchDGCs = async () => {
            try {
                const result = await api().get("/dgcs");
                setDGCs(result.data);
            } catch (error) {
                console.error("Error fetching dgcs:", error);
            }
        };
        const fetchCultivos = async () => {
            try {
                const result = await api().get(`/catalogos/productos_cultivo`);
                setCultivos(result.data);

            } catch (error) {
                console.error("Error fetching productos_cultivo:", error);
            }
        };
        const fetchProductosFito1 = async () => {
            try {
                const result = await api().get(`/catalogos/productos_fito`);
                setProductos(result.data);

            } catch (error) {
                console.error("Error fetching productos_fito:", error);
            }
        };
        const fetchNoComunes = async () => {
            try {
                const result = await api().get(`/cultivos_no_comunes`);
                setCultivosNoComunes(result.data);

            } catch (error) {
                console.error("Error fetching productos_fito:", error);
            }
        };

        fetchNoComunes();
        fetchCultivos();
        fetchProductosFito1();
        fetchAplicadores();
        fetchEquipos();
        fetchTiposMedida();
        fetchIdentificadores();
        fetchDGCs();
        fetchEficacias();
        fetchJustificaciones();
        fetchUnidades();

    }, []);

    const [equipoValues, setEquipoValues] = useState({
        numeroRoma: null,
        numeroReganip: null,
        equipoPropio: false,
        idEquipoAplicador: null,
    });
    const [listsData, setListsData] = useState({
        dgcs_ids: [],
        identificadores_aplicadores_ids: [],
        justificaciones_codigos: [],
    });
    const [productosFitoSchemas, setProductosFitoSchemas] = useState([]);

    const [initialProductoData, setInitialProductoData] = useState({
        tipo_producto: null,
        num_registro: null,
        id_uso: null,
        dosis: null,
        cantidad: null,
        unidad: null,
        materia_activa: [],
    });
    const [productoData, setProductoData] = useState({ ...initialProductoData });
    const [fitoData, setFitoData] = useState({
        id_ajena_tratam_fito: null,
        fecha_inicio: null,
        fecha_fin: null,
        fecha_seca: null,
        eficacia: null,
        observaciones: null,
    });
    const [asesorData, setAsesorData] = useState({
        num_ropo: null,
        fecha: null,
        validacion: null,
        observaciones: null,
        confirmacion: null,
        contrato: null,
    });
    const [otrasActuacionesFitoData, setOtrasActuacionesFitoData] = useState({
        tipo_medida: null,
        cantidad: 0,
        unidad: null,
        num_registro_mdf: null,
    });

    const handleFieldChange = (e) => {
        setSelectedField(e.target.value);
    };
    const handleFechaInicioChange = (e) => {
        setFitoData({ ...fitoData, ['fecha_inicio']: e });
    };
    const handleFechaFinChange = (e) => {
        setFitoData({ ...fitoData, ['fecha_fin']: e });
    };
    const handleChangeFitoData = (event) => {
        const { name, value } = event.target;
        const updatedValue = name === "id_ajena_tratam_fito" ? parseInt(value, 10) : value;
        setFitoData({ ...fitoData, [name]: updatedValue });
    };
    const handleOtrasActFitoData = (event) => {
        const { name, value } = event.target;
        const updatedValue = name === "cantidad" ? parseInt(value, 10) : value;
        const sanitizedValue = isNaN(updatedValue) ? null : updatedValue;

        setOtrasActuacionesFitoData((prevData) => ({
            ...prevData,
            [name]: sanitizedValue,
        }));

    };
    const handleAsesorData = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = name === "num_ropo" ? parseInt(value, 10) : type === "checkbox" ? checked : value;

        setAsesorData({ ...asesorData, [name]: inputValue });
    };
    const handleEficacia = (event, newValue) => {
        if (newValue) {
            setSelectedEficacia(newValue);
            setFitoData({ ...fitoData, eficacia: newValue.codigo_siex });
        }
    };
    const handleTipoMedida = (event, newValue) => {
        if (newValue) {
            setSelectedTipoMedida(newValue);
            setOtrasActuacionesFitoData({ ...otrasActuacionesFitoData, tipo_medida: newValue.codigo_siex });
        }
    };
    const handleUnidadOtraActuacion = (event, newValue) => {
        if (newValue) {
            setSelectedUnidadOtraActuacion(newValue);
            setOtrasActuacionesFitoData({ ...otrasActuacionesFitoData, unidad: newValue.codigo_siex });
        }
    };
    const handleAddJustificacion = () => {
        if (selectedJustificacion && selectedJustificacion.just_act) {
            if (!listsData.justificaciones_codigos.includes(selectedJustificacion.just_act)) {
                setListsData({
                    ...listsData,
                    justificaciones_codigos: [...listsData.justificaciones_codigos, selectedJustificacion.just_act],
                });
            }
        }
    };
    const handleDeleteJustificacion = (index) => {
        const updatedJustificaciones = [...listsData.justificaciones_codigos];
        updatedJustificaciones.splice(index, 1);
        setListsData({ ...listsData, justificaciones_codigos: updatedJustificaciones });
    };
    const handleInputChange = (e, action = 'add') => {
        const { name, value } = e.target;
        const newValue = (name === 'dosis') || (name === 'cantidad') ? parseFloat(value) : value;
        const sanitizedValue = isNaN(newValue) ? null : newValue;
        setProductoData({ ...productoData, [name]: sanitizedValue });
    };
    const handleAddDGC = () => {
        if (selectedDGC && selectedDGC.id) {
            if (!listsData.dgcs_ids.includes(selectedDGC.id)) {
                setListsData({
                    ...listsData,
                    dgcs_ids: [...listsData.dgcs_ids, selectedDGC.id],
                });
            }
        }
    };
    const handleDeleteDGC = (index) => {
        const updatedDGCs = [...listsData.dgcs_ids];
        updatedDGCs.splice(index, 1);
        setListsData({ ...listsData, dgcs_ids: updatedDGCs });
    };
    const handleAddIdentificador = () => {
        if (selectedIdentificador && selectedIdentificador.id) {
            if (!listsData.identificadores_aplicadores_ids.includes(selectedIdentificador.id)) {
                setListsData({
                    ...listsData,
                    identificadores_aplicadores_ids: [...listsData.identificadores_aplicadores_ids, selectedIdentificador.id],
                });
            }
        }
    };
    const handleDeleteIdentificador = (index) => {
        const updatedIdentificadores = [...listsData.identificadores_aplicadores_ids];
        updatedIdentificadores.splice(index, 1);
        setListsData({ ...listsData, identificadores_aplicadores_ids: updatedIdentificadores });
    };
    const createTratam = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            
            const result = await api().post(`/cuadernos/${id}/tratam_fito/new`, {
                cuaderno_id: parseInt(id),
                otras_actuaciones_fito_data: otrasActuacionesFitoData,
                tratam_fito_data: fitoData,
                asesor_data: asesorData,
                dgcs_ids: listsData.dgcs_ids,
                productos_fito_schemas: productosFitoSchemas,
                justificaciones_codigos: listsData.justificaciones_codigos,
                identificadores_aplicadores_ids: listsData.identificadores_aplicadores_ids,
            });

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Tratamiento creado correctamente");
                    setError(null);
                    navigate(`/cuadernos/${id}/tratamientos_fito`);
                }
            } 
            else if (result.status == 422){
                setError(`Debe rellenar todos los campos obligatorios`);

            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }

        } catch (error) {

            if (error.response.status == 422){
                setError(`Debe rellenar todos los campos.`);
            }else{
                setError(`Error: ${error.response.data.detail}`);
            }
            

        } finally {
            setLoading(false);
        }
    };
    const createIdentificadorEmpresa = async (aplicadorEmpresaId, equipoAplicadorId) => {
        try {
            setLoading(true);

            const url = `/identificadores_aplicadores/new?aplicador_empresa_id=${aplicadorEmpresaId}&equipo_aplicador_id=${equipoAplicadorId}`;

            const result = await api().post(url);

            if (result.status < 300) {
                if (result.data && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    alert("IdentificadorEmpresa creado correctamente");
                    setError(null);
                    fetchIdentificadores();
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);

        } finally {
            setLoading(false);
            setIdentificadorModalOpen(false);
        }
    };
    const createAplicadorEmpresa = async (num_ropo) => {
        try {
            setLoading(true);

            const result = await api().post(`/aplicadores_empresa/new?num_ropo=${num_ropo}`);

            if (result.status < 300) {
                if (result.data && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    alert("AplicadorEmpresa creado correctamente");
                    setError(null);
                    fetchAplicadores();
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);

        } finally {
            setLoading(false);
        }
    };
    const createEquipoAplicador = async () => {
        try {
            setLoading(true);
            const result = await api().post('/equipos_aplicadores/new', {
                num_roma: equipoValues.numeroRoma,
                num_reganip: equipoValues.numeroReganip,
                equipo_propio: equipoValues.equipoPropio,
                id_equipo_aplicador: equipoValues.idEquipoAplicador
            });

            if (result.status < 300) {
                if (result.data && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    alert("EquipoAplicador creado correctamente");
                    setError(null);
                    fetchEquipos();
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);

        } finally {
            setLoading(false);
        }
    };
    const openIdentificadorModal = () => {
        setIdentificadorModalOpen(true);
    };
    const closeIdentificadorModal = () => {
        setIdentificadorModalOpen(false);
    };
    const openProductoModal = () => {
        setProductoModalOpen(true);
    };
    const closeProductoModal = () => {
        setProductoModalOpen(false);
    };
    const removeProductoFitoSchema = (index) => {
        const updatedSchemas = [...productosFitoSchemas];
        updatedSchemas.splice(index, 1);
        setProductosFitoSchemas(updatedSchemas);
    };
    const handleUnidadProducto = (event, newValue) => {
        if (newValue) {
            setSelectedUnidadProducto(newValue);
            setProductoData({ ...productoData, unidad: newValue.codigo_siex });
        }
    };
    const handleChangeUso = (uso) => {
        setSelectedUso(uso);
        setProductoData({ ...productoData, id_uso: uso });

    };
    const handleAddMateriaActiva = (materiaActiva) => {
        const materiaActivaInt = parseInt(materiaActiva, 10);
        if (!isNaN(materiaActivaInt) && materiaActivaInt.toString().length === 5) {
            setProductoData({
                ...productoData,
                materia_activa: [...productoData.materia_activa, materiaActivaInt],
            });
        } else {
            alert("Ingrese un número de 5 dígitos válido.");
        }
    };
    const handleDeleteMateriaActiva = (index) => {
        const updatedMateriasActivas = [...productoData.materia_activa];
        updatedMateriasActivas.splice(index, 1);
        setProductoData({ ...productoData, materia_activa: updatedMateriasActivas });
    };
    const addProductoSchema = async () => {
        if (productoData.id_uso!= null && productoData.tipo_producto != null && (productoData.dosis != null || productoData.cantidad != null
        ) && productoData.unidad != null && (productoData.num_registro != null || productoData.materia_activa.length > 0)) {
            try {
                setLoading(true);

                const result = await api().post(`/productos_fito/check_tratam_fito`, productoData);

                if (result.status < 300) {
                    if (result.data != null && result.data.status_code >= 300) {
                        alert(`Error: ${result.data.detail}`);
                    } else {
                        setProductosFitoSchemas((prevSchemas) => [...prevSchemas, { ...productoData }]);
                        setSelectedCultivo(null);
                        setSelectedCultivoNoComun(null);
                        setSelectedProducto(null);
                        setSelectedUso(null);
                        setSelectedUnidadProducto(null);
                        setProductoData({ ...initialProductoData });
                        alert("Producto añadido correctamente");
                        setError(null);
                    }
                } else {
                    alert(`Error ${result.status}: ${result.data.detail}`);

                }
            } catch (error) {

                setError(`Error: ${error.response.data.detail}`);

            } finally {
                setLoading(false);
            }
        }
        else {
            alert("Debe rellenar todos los campos a excepcion de numero de registro, que podrá no rellenarlo si añade alguna materia activa")
        }
    };
    return (
        <Paper elevation={3} style={{ padding: 20, maxWidth: 800 }}>
            <Typography component="h2" variant="h5">
                Nuevo tratamiento fitosanitario
            </Typography>
            <br></br>
            <form onSubmit={createTratam}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            label="ID Ajena Tratamiento Fito"
                            name="id_ajena_tratam_fito"
                            value={fitoData.id_ajena_tratam_fito}
                            onChange={handleChangeFitoData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateTimePickerComponent
                            required
                            fullWidth
                            label={"Fecha de Inicio"}
                            name="fecha_inicio"
                            onChange={handleFechaInicioChange}
                            value={fitoData.fecha_inicio}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateTimePickerComponent
                            required
                            fullWidth
                            label={"Fecha de Fin"}
                            name="fecha_fin"
                            value={fitoData.fecha_fin}
                            onChange={handleFechaFinChange}

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="fecha_seca">Fecha de Seca</InputLabel>
                        <Input
                            id="fecha_seca"
                            type="date"
                            name="fecha_seca"
                            value={fitoData.fecha_seca}
                            onChange={handleChangeFitoData}
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={eficacias}
                            getOptionLabel={(option) => option.codigo_siex?.toString() + "   " + option.eficacia_tratamiento?.toString() || ""}
                            value={selectedEficacia}
                            onChange={handleEficacia}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Eficacia"
                                    fullWidth
                                    margin="normal"

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Observaciones"
                            multiline
                            rows={4}
                            name="observaciones"
                            value={fitoData.observaciones}
                            onChange={handleChangeFitoData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <br></br>
                        <Typography variant="h6" gutterBottom>Justificaciones</Typography>

                        <Autocomplete
                            options={justificaciones}
                            getOptionLabel={(option) => option.just_act?.toString() + "   " + option.justificacion?.toString() || ""}
                            value={selectedJustificacion}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setSelectedJustificacion(newValue);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Justificaciones"
                                    fullWidth
                                    margin="normal"

                                />
                            )}
                        />
                        <Grid item xs={4}>
                            <Button variant="outlined" color="primary" onClick={handleAddJustificacion}>
                                Añadir
                            </Button>
                        </Grid>
                        <br></br>

                        <div>
                            <Typography variant="h6" gutterBottom>Justificaciones añadidas</Typography>

                            <div>
                                {listsData.justificaciones_codigos.map((justificacion, index) => (
                                    <Chip
                                        key={index}
                                        label={justificacion}
                                        style={{ marginRight: 8, marginBottom: 8 }}
                                        onDelete={() => handleDeleteJustificacion(index)}
                                    />
                                ))}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>DGCs</Typography>

                        <Autocomplete
                            options={dgcs}
                            getOptionLabel={(option) => '//ID: ' + option.id?.toString() + "   //Producto cosechado: " + option.producto_cosechado?.toString() + "   //Variedad: " + option.variedad?.toString() || ""}
                            value={selectedDGC}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setSelectedDGC(newValue);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="DGCs"
                                    fullWidth
                                    margin="normal"

                                />
                            )}
                        />
                        <Grid item xs={4}>
                            <Button variant="outlined" color="primary" onClick={handleAddDGC}>
                                Añadir
                            </Button>
                        </Grid>
                        <br></br>

                        <div>
                            <Typography variant="h6" gutterBottom>DGCs añadidas</Typography>

                            <div>
                                {listsData.dgcs_ids.map((dgc, index) => (
                                    <Chip
                                        key={index}
                                        label={dgc}
                                        style={{ marginRight: 8, marginBottom: 8 }}
                                        onDelete={() => handleDeleteDGC(index)}
                                    />
                                ))}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>Identificadores</Typography>

                        <Autocomplete
                            options={identificadores}
                            getOptionLabel={(option) => "Aplicador:" + option.aplicador_empresa.num_ropo + "  //  Equipo:" + option.equipo_aplicador_tratam.id || ""}
                            value={selectedIdentificador}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setSelectedIdentificador(newValue);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Identificadores"
                                    fullWidth
                                    margin="normal"

                                />
                            )}
                        />
                        <Button variant="outlined" color="primary" onClick={openIdentificadorModal}>
                            Crear nuevo identificador
                        </Button>

                        <Modal open={identificadorModalOpen} onClose={closeIdentificadorModal}>
                            <Paper elevation={3} style={{ padding: 20, maxWidth: 400, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <h1>Crear Identificador</h1>
                                <Grid item xs={12}>
                                    <h3>Crear Aplicador Empresa</h3>
                                    <TextField
                                        label="Número Ropo"
                                        onChange={(e) => setNumeroRopo(e.target.value)}
                                        fullWidth
                                        margin="normal"
                                    />
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => createAplicadorEmpresa(numeroRopo)}
                                        disabled={loading}
                                    >
                                        Crear Aplicador Empresa
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <h3>Crear Equipo Aplicador</h3>
                                    <FormControl fullWidth>
                                        <InputLabel>Atributo</InputLabel>
                                        <Select
                                            label="Atributo"
                                            value={selectedAttribute}
                                            onChange={(e) => setSelectedAttribute(e.target.value)}
                                        >
                                            <MenuItem value="numeroRoma">Número Roma</MenuItem>
                                            <MenuItem value="numeroReganip">Número Reganip</MenuItem>
                                            <MenuItem value="idEquipoAplicador">ID Equipo Aplicador</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        label={selectedAttribute === "idEquipoAplicador" ? "ID Equipo Aplicador" : `Número ${selectedAttribute}`}
                                        onChange={(e) => {
                                            let newValues = Object.keys(equipoValues).reduce((obj, key) => {
                                                obj[key] = null;
                                                return obj;
                                            }, {});

                                            newValues[selectedAttribute] = e.target.value;

                                            setEquipoValues(newValues);
                                        }}
                                        fullWidth
                                        required
                                        margin="normal"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={equipoValues.equipoPropio}
                                                onChange={(e) => setEquipoValues({ ...equipoValues, equipoPropio: e.target.checked })}
                                            />
                                        }
                                        label="Equipo Propio"
                                    />
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={createEquipoAplicador}
                                        disabled={loading}
                                    >
                                        Crear Equipo Aplicador
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <h3>Crear Identificador Empresa</h3>
                                    <FormControl fullWidth>
                                        <InputLabel>Aplicador Empresa</InputLabel>
                                        <Select
                                            label="Aplicador Empresa"
                                            onChange={(e) => setAplicadorEmpresaId(e.target.value)}
                                        >
                                            {aplicadores.map((aplicador) => (
                                                <MenuItem key={aplicador.id} value={aplicador.id}>
                                                    {aplicador.num_ropo}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel>Equipo Aplicador</InputLabel>
                                        <Select
                                            label="Equipo Aplicador"
                                            onChange={(e) => setEquipoAplicadorId(e.target.value)}
                                        >
                                            {equipos.map((equipo) => (
                                                <MenuItem key={equipo.id} value={equipo.id}>
                                                    {equipo.num_reganip ? `Número REGANIP: ${equipo.num_reganip}` :
                                                        equipo.num_roma ? `Número ROMA: ${equipo.num_roma}` :
                                                            `ID Aplicador: ${equipo.id_aplicador}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => createIdentificadorEmpresa(aplicadorEmpresaId, equipoAplicadorId)}
                                        disabled={loading}
                                        margin="normal"
                                    >
                                        Crear Identificador Empresa
                                    </Button>
                                </Grid>
                            </Paper>
                        </Modal>


                        <Grid item xs={4}>
                            <Button variant="outlined" color="primary" onClick={handleAddIdentificador}>
                                Añadir
                            </Button>
                        </Grid>
                        <br></br>
                        <div>
                            <Typography variant="h6" gutterBottom>Identificadores añadidos</Typography>

                            <div>
                                {listsData.identificadores_aplicadores_ids.map((identificador, index) => (
                                    <Chip
                                        key={index}
                                        label={identificador}
                                        style={{ marginRight: 8, marginBottom: 8 }}
                                        onDelete={() => handleDeleteIdentificador(index)}
                                    />
                                ))}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>Productos Fitosanitarios empleados</Typography>

                        <Button variant="outlined" color="primary" onClick={openProductoModal}>
                            Añadir productos
                        </Button>
                        <Modal open={productoModalOpen} onClose={closeProductoModal}>
                            <Paper elevation={3} style={{ padding: 20, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxHeight: '80vh', overflowY: 'auto', }}>
                                <Typography variant="h6" gutterBottom>Añadir Producto Fitosanitario</Typography>

                                <Grid item xs={12}>
                                    {cultivos && (
                                        <Autocomplete
                                            options={cultivos}
                                            getOptionLabel={(cultivo) => cultivo.producto_siex}
                                            style={{ width: 400 }}
                                            onChange={(event, newValue) => setSelectedCultivo(newValue)}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Selecciona un cultivo" />
                                            )}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {cultivosNoComunes && abierto && (
                                        <>
                                            <Typography variant="body1" paragraph>
                                                El cultivo seleccionado no coincide con ningún uso fitosanitario. Selecciona un cultivo similar.
                                            </Typography>
                                            <Autocomplete
                                                options={cultivosNoComunes}
                                                getOptionLabel={(cultivo) => cultivo}
                                                style={{ width: 400, marginTop: 20 }}
                                                onChange={(event, newValue) => setSelectedCultivoNoComun(newValue)}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Selecciona un cultivo similar" />
                                                )}
                                            />
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={12}>

                                    {selectedCultivo && productos && (
                                        <>
                                            <Autocomplete
                                                options={productos}
                                                getOptionLabel={(producto) => producto.numero_registro + ' ' + producto.nombre}
                                                style={{ width: 400, marginTop: 20 }}
                                                onChange={(event, newValue) => setSelectedProducto(newValue)}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Selecciona un producto" />

                                                )}
                                            />

                                        </>
                                    )}
                                </Grid>
                                {selectedProducto && productoInfo && (
                                    <>
                                        <Box margin="2rem">
                                            <TableContainer component={Paper}>
                                                <Typography variant="h5" component="h5" gutterBottom>
                                                    Producto fitosanitario
                                                </Typography>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>SIEX ID</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Nombre</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Estado</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Nº Registro</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Formulado</Typography>

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow key={productoInfo.id}>
                                                            <TableCell component="th" scope="row">
                                                                {productoInfo.siex_id}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {productoInfo.nombre}
                                                            </TableCell>
                                                            <TableCell>
                                                                {productoInfo.siex_estado}

                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {productoInfo.numero_registro}

                                                            </TableCell>
                                                            <TableCell>
                                                                {productoInfo.formulado}
                                                            </TableCell>
                                                        </TableRow>


                                                    </TableBody>
                                                </Table>
                                                <br />

                                                <Typography variant="h5" component="h5" gutterBottom>
                                                    Usos
                                                </Typography>

                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Cultivo</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Agentes</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Dosis MIN</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Dosis MAX</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Ud. Dosis</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Volumen MIN</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Volumen MAX</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Ud. Volumen</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Aplicaciones</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Intervalo de aplicaciones</Typography>

                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography variant="h6" gutterBottom>Seleccionar</Typography>

                                                            </TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {productoInfo.usos.map(uso => (

                                                            <TableRow key={uso.id}>
                                                                <TableCell component="th" scope="row">
                                                                    {uso.cultivo || "No especificado"}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {uso.agente || "No especificado"}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {uso.dosis_min || "No especificado"}

                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {uso.dosis_max || "No especificado"}

                                                                </TableCell>
                                                                <TableCell component="th" scope="row">

                                                                    {uso.unidad_dosis || "No especificado"}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">

                                                                    {uso.vol_min || "No especificado"}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">

                                                                    {uso.vol_max || "No especificado"}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">

                                                                    {uso.unidad_vol || "No especificado"}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">

                                                                    {uso.aplicaciones || "No especificado"}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">

                                                                    {uso.intervalo_aplicaciones || "No especificado"}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    <FormControlLabel
                                                                        key={uso.id}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={selectedUso === uso.id}
                                                                                onChange={() => handleChangeUso(uso.id)}
                                                                            />
                                                                        }
                                                                    />
                                                                </TableCell>

                                                            </TableRow>

                                                        ))}
                                                    </TableBody>
                                                </Table>

                                            </TableContainer>
                                        </Box>

                                        <br />
                                        <Grid item xs={12}>

                                            <FormControl fullWidth>
                                                <InputLabel id="select-field-label">Seleccionar Campo</InputLabel>
                                                <Select
                                                    labelId="select-field-label"
                                                    id="select-field"
                                                    value={selectedField}
                                                    onChange={handleFieldChange}
                                                >
                                                    <MenuItem value="dosis">Dosis</MenuItem>
                                                    <MenuItem value="cantidad">Cantidad</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                name={selectedField}
                                                value={productoData[selectedField]}
                                                label="Introduce dosis o cantidad"
                                                onChange={handleInputChange}
                                                margin="normal"
                                                type="number"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Autocomplete
                                                options={unidades}
                                                getOptionLabel={(option) => option.unidades_medida?.toString() || ""}
                                                value={selectedUnidadProducto}
                                                onChange={handleUnidadProducto}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Unidad"
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MateriasActivasInput onAddMateriaActiva={handleAddMateriaActiva} />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div>
                                                <Typography variant="h6" gutterBottom>Materias activas añadidas</Typography>
                                                <div>
                                                    {productoData.materia_activa.map((materiaActiva, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={materiaActiva}
                                                            style={{ marginRight: 8, marginBottom: 8 }}
                                                            onDelete={() => handleDeleteMateriaActiva(index)}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </Grid>
                                        <br></br>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={addProductoSchema}
                                            >
                                                Añadir producto fito
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                                {error && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error}
                                    </Typography>
                                )}
                            </Paper>
                        </Modal>
                        <div>
                            <br></br>

                            <Typography variant="h6" gutterBottom>Productos añadidos</Typography>

                            <ProductosFitoTable
                                productosFitoSchemas={productosFitoSchemas}
                                onRemove={removeProductoFitoSchema}
                            />
                        </div>
                    </Grid>
                </Grid>

                <br></br>

                <Typography variant="h6" gutterBottom>Información del asesor (opcional)</Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <TextField
                            fullWidth
                            label="Número Ropo"
                            name="num_ropo"
                            value={asesorData.num_ropo}
                            onChange={handleAsesorData}
                            margin="normal"
                        />
                        <InputLabel htmlFor="fecha">Fecha Actuación</InputLabel>
                        <Input
                            id="fecha"
                            type="date"
                            name="fecha"
                            value={asesorData.fecha}
                            onChange={handleAsesorData}
                            fullWidth
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Validación</InputLabel>
                            <Select
                                name="validacion"
                                value={asesorData.validacion || ""}
                                onChange={handleAsesorData}
                            >
                                <MenuItem value={true}>Sí</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            label="Observaciones"
                            name="observaciones"
                            multiline
                            rows={3}
                            value={asesorData.observaciones}
                            onChange={handleAsesorData}
                            margin="normal"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={asesorData.confirmacion || false} onChange={handleAsesorData} name="confirmacion" />}
                            label="Confirmación"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={asesorData.contrato || false} onChange={handleAsesorData} name="contrato" />}
                            label="Contrato"
                        />
                    </Grid>
                </Grid>
                <br></br>

                <Typography variant="h6" gutterBottom>Información de otra actuacion fitosanitaria (opcional)</Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={tiposMedida}
                            getOptionLabel={(option) => option.codigo_siex?.toString() + "   " + option.tipo_medida?.toString() || ""}
                            value={selectedTipoMedida}
                            onChange={handleTipoMedida}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Tipo de Medida"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Cantidad"
                            name="cantidad"
                            value={otrasActuacionesFitoData.cantidad}
                            onChange={handleOtrasActFitoData}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={unidades}
                            getOptionLabel={(option) => option.codigo_siex?.toString() + "   " + option.unidades_medida?.toString() || ""}
                            value={selectedUnidadOtraActuacion}
                            onChange={handleUnidadOtraActuacion}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Unidad"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Numero de registro MDF"
                            name="num_registro_mdf"
                            value={otrasActuacionesFitoData.num_registro_mdf}
                            onChange={handleOtrasActFitoData}
                        />
                    </Grid>
                </Grid>
                <br></br>

                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        Crear Tratamiento
                    </Button>
                </Grid>
            </form>
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </Paper >
    );
}

export default TratamFitoNew;

import React, { useState, useEffect,useCallback } from "react";
import { useParams } from 'react-router-dom';
import { api } from "../../login/OAuth";

import { TextField, Button, Grid, Typography, Autocomplete } from "@mui/material";


const TratamPostCosechaEdit = () => {
    const { id } = useParams();
    const [tratam, setTratam] = useState(null);
    const [productosVegetales, setProductosVegetales] = useState(null);
    const [selectedProductoVegetal, setSelectedProductoVegetal] = useState(null);
    const [eficacias, setEficacias] = useState(null);
    const [selectedEficacia, setSelectedEficacia] = useState(null);
    const [error, setError] = useState(null);
    const [tratamientoData, setTratamientoData] = useState({
        id_ajena_tratam_postco: "",
        fecha_actuacion: "",
        producto_vegetal: "",
        cantidad: "",
        eficacia: "",
        observaciones: "",
    });

    const fetchTratam = useCallback(async () => {
        try {
            const resultPV = await api().get(`/catalogos/prod_vegetales`);
            setProductosVegetales(resultPV.data);

            const resultE = await api().get(`/catalogos/eficacias`);
            setEficacias(resultE.data);

            const result = await api().get(`/tratamientos_post_cosecha/${id}`);
            setTratam(result.data);

            if (result.data) {
                setTratamientoData(result.data);

                const selectedProductoVegetal = resultPV.data.find(producto => producto.codigo_siex === result.data.producto_vegetal);
                if (selectedProductoVegetal) {
                    setSelectedProductoVegetal(selectedProductoVegetal);
                } else {
                    console.error("Error: Producto vegetal no encontrado");
                }

                const selectedEficacia = resultE.data.find(eficacia => eficacia.codigo_siex === result.data.eficacia);
                if (selectedEficacia) {
                    setSelectedEficacia(selectedEficacia);
                } else {
                    console.error("Error: Eficacia no encontrada");
                }
            }
        } catch (error) {
            console.error("Error fetching tratamiento:", error);
        }
    },[id]);

    useEffect(() => {
        fetchTratam();
    }, [fetchTratam]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTratamientoData({ ...tratamientoData, [name]: value });
    };
    const handleProductoVegetales = (event, newValue) => {
        if (newValue) {
            console.log(newValue)
            setSelectedProductoVegetal(newValue);
            setTratamientoData({ ...tratamientoData, producto_vegetal: newValue.codigo_siex });
        }
    };
    const handleEficacia = (event, newValue) => {
        if (newValue) {
            setSelectedEficacia(newValue);
            setTratamientoData({ ...tratamientoData, eficacia: newValue.codigo_siex });
        }
    };
    const handleEditTratamiento = async () => {
        try {
            const result = await api().patch(`/tratamientos_post_cosecha/${id}/update`, tratamientoData);

            if (result.status < 300) {
                alert("Tratamiento actualizado correctamente");
                fetchTratam();
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        }
    };

    return (
        <div>
            {tratam ? (<Grid>
                <Typography variant="h6" gutterBottom>
                    Editar tratamiento
                </Typography>
                <form onSubmit={handleEditTratamiento}>
                    <TextField
                        fullWidth
                        label="IdAjenaTratamPostco"
                        name="id_ajena_tratam_postco"
                        value={tratamientoData.id_ajena_tratam_postco}
                        onChange={handleInputChange}
                        required
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="FechaActuacion"
                        name="fecha_actuacion"
                        type="date"
                        required
                        value={tratamientoData.fecha_actuacion}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <Autocomplete
                        options={productosVegetales}
                        getOptionLabel={(option) => option.codigo_siex?.toString() + "   " + option.producto?.toString() || ""}
                        value={selectedProductoVegetal}
                        onChange={handleProductoVegetales}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Producto vegetal"
                                fullWidth
                                margin="normal"
                                required

                            />
                        )}
                    />
                    <TextField
                        fullWidth
                        label="Cantidad"
                        name="cantidad"
                        value={tratamientoData.cantidad}
                        onChange={handleInputChange}
                        required
                        margin="normal"
                    />
                    <Autocomplete
                        options={eficacias}
                        getOptionLabel={(option) => option.codigo_siex?.toString() + "   " + option.eficacia_tratamiento?.toString() || ""}
                        value={selectedEficacia}
                        onChange={handleEficacia}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Eficacia"
                                fullWidth
                                margin="normal"

                            />
                        )}
                    />
                    <TextField
                        fullWidth
                        label="Observaciones"
                        name="observaciones"
                        value={tratamientoData.observaciones}
                        onChange={handleInputChange}
                        margin="normal"
                    />

                    <Button type="button" variant="contained" color="primary" onClick={handleEditTratamiento}>
                        Guardar
                    </Button>
                </form>

            </Grid>
            ) : (
                <>cargando</>
            )
            }
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div >
    );
};

export default TratamPostCosechaEdit;
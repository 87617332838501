import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { api } from '../login/OAuth';
import { TextField, Paper, Autocomplete, Typography } from "@mui/material";

function Cuaderno() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [comunidades, setComunidades] = useState([]);
    const [comunidad, setComunidad] = useState();
    const [error, setError] = useState(null);

    const [registerForm, setRegisterform] = useState({
        codigo_siex: "",
        ca_explotacion: "",
        id_titular: "",
        unidad_gestora: "",
        id_cuaderno: "",
        codigo_rea: "",
    });

    useEffect(() => { fetchComunidades(); }, []);

    const fetchComunidades = async () => {
        try {
            const result = await api().get("/catalogos/comunidades");
            setComunidades(result.data);
        } catch (error) {
            console.error("Error fetching comunidades:", error);
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const valor = name === "id_cuaderno" ? parseInt(value, 10) : value;

        setRegisterform({ ...registerForm, [name]: valor });
    };

    const createCuaderno = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);

            const result = await api().post('/cuadernos/new', registerForm);
            console.log(result)

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Cuaderno creado correctamente");
                    navigate(`/cuadernos/list`);
                    setError(null)
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);

        } finally {
            setLoading(false);
        }
    };

    const handleComunidad = (event, newValue) => {
        if (newValue) {
            setRegisterform({ ...registerForm, ca_explotacion: newValue.codigo_ine });
            setComunidad(newValue);
        }
    };

    return (
        <Paper elevation={3} style={{ padding: 20 }}>
            <Typography component="h2" variant="h5">
                Nuevo cuaderno
            </Typography>
            <br></br>
            <form onSubmit={createCuaderno}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="codigo_siex"
                            required
                            fullWidth
                            id="codigo_siex"
                            label="Código SIEX"
                            value={registerForm.codigo_siex}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={comunidades.sort((a, b) => a.codigo_ine - b.codigo_ine)}
                            getOptionLabel={(option) => option.codigo_ine?.toString() + "   " + option.descripcion?.toString() || ""}
                            value={comunidad}
                            onChange={handleComunidad}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="CA Explotacion"
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="id_titular"
                            required
                            fullWidth
                            id="id_titular"
                            label="ID del titular"
                            value={registerForm.id_titular}
                            onChange={handleInputChange}
                            helperText="Tipo CIF/NIE. Ejemplo: A12345678"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="unidad_gestora"
                            required
                            fullWidth
                            id="unidad_gestora"
                            label="Unidad gestora"
                            value={registerForm.unidad_gestora}
                            onChange={handleInputChange}
                            helperText="Tipo CIF/NIE. Ejemplo: A12345678"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="id_cuaderno"
                            required
                            fullWidth
                            id="id_cuaderno"
                            label="Id del cuaderno"
                            value={registerForm.id_cuaderno}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="codigo_rea"
                            required
                            fullWidth
                            id="codigo_rea"
                            label="Código rea"
                            value={registerForm.codigo_rea}
                            onChange={handleInputChange}
                            helperText="Tipo REA. Ejemplo: ES123456789012"

                        />
                    </Grid>
                </Grid>
                <br />
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        Crear cuaderno
                    </Button>
                </Grid>
            </form>
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </Paper >


    );
}

export default Cuaderno
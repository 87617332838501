import React, { useState, useEffect } from "react";
import { api } from "../login/OAuth"
import { useParams } from 'react-router-dom';
import ReactJson from "react-json-view";
import {Typography} from "@mui/material";


function ExportarCue() {
    const [data, setData] = useState([]);
    const { id } = useParams();
    useEffect(() => {
        async function exportCue() {

            try {
                const result = await api().get(`/cuadernos/${id}/export`);

                if (result.status === 200) {
                    setData(result.data);

                } else {
                    alert(`Error: ${result.data.detail}`);
                }
            } catch (error) {
                alert("Error", error);
            }
        }
        exportCue();
    }, [id]);

    return (
        <div>
            <Typography variant="h6" gutterBottom>Exportar Cuaderno</Typography>

            {data && <ReactJson src={data} theme="google" collapsed={1} />}
        </div>
    )
}
export default ExportarCue;
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from 'react-router-dom';
import { api } from "../../login/OAuth";

import { TextField, Button, Grid, Autocomplete, Typography } from "@mui/material";


const TratamFitoEditOtrasAct = () => {
    const { id } = useParams();
    const [tratam, setTratam] = useState(null);
    const [unidades, setUnidades] = useState([]);
    const [selectedUnidadOtraActuacion, setSelectedUnidadOtraActuacion] = useState(null);
    const [tiposMedida, setTiposMedida] = useState([]);
    const [selectedTipoMedida, setSelectedTipoMedida] = useState(null);
    const [error, setError] = useState(null);
    const [otrasActuacionesFitoData, setOtrasActuacionesFitoData] = useState({
        tipo_medida: null,
        cantidad: 0,
        unidad: null,
        num_registro_mdf: 0,
    });

    const fetchTratam = useCallback(async () => {
        try {
            const result = await api().get(`/tratam_fitos/${id}`);
            setTratam(result.data);
            const resultUni = await api().get(`/catalogos/unidades`);

            const resultTM = await api().get(`/catalogos/tipos_medida_fito`);

            if (result.data.otras_actuaciones_fito) {
                setOtrasActuacionesFitoData(result.data.otras_actuaciones_fito);

                const selectedUni = resultUni.data.find(unidad => parseInt(unidad.codigo_siex) === parseInt(result.data.otras_actuaciones_fito.unidad));
                if (selectedUni) {
                    setSelectedUnidadOtraActuacion(selectedUni);
                } else {
                    console.error("Error: Unidad no encontrada");
                }

                const selectedTM = resultTM.data.find(unidad => parseInt(unidad.codigo_siex) === parseInt(result.data.otras_actuaciones_fito.tipo_medida));
                if (selectedTM) {
                    setSelectedTipoMedida(selectedTM);
                } else {
                    console.error("Error: Tipo de medida no encontrada");
                }
            }
        } catch (error) {
            console.error("Error fetching tratamiento:", error);
        }
    }, [id]);
    useEffect(() => {
        fetchUnidades();
        fetchTiposMedida();
        fetchTratam();
    }, [fetchTratam]);

    const fetchUnidades = async () => {
        try {
            const result = await api().get(`/catalogos/unidades`);
            setUnidades(result.data);

        } catch (error) {
            console.error("Error fetching unidades:", error);
        }
    };
    const fetchTiposMedida = async () => {
        try {
            const result = await api().get(`/catalogos/tipos_medida_fito`);
            console.log(result.data)
            setTiposMedida(result.data);

        } catch (error) {
            console.error("Error fetching tipos_medida:", error);
        }
    };

    const handleOtrasActFitoData = (event) => {
        const { name, value } = event.target;

        const updatedValue = name === "cantidad" ? parseInt(value, 10) : value;
        const sanitizedValue = isNaN(updatedValue) ? null : updatedValue;

        setOtrasActuacionesFitoData((prevData) => ({
            ...prevData,
            [name]: sanitizedValue,
        }));

    };

    const handleTipoMedida = (event, newValue) => {
        if (newValue) {
            setSelectedTipoMedida(newValue);
            setOtrasActuacionesFitoData({ ...otrasActuacionesFitoData, tipo_medida: newValue.codigo_siex });
        }
    };
    const handleUnidadOtraActuacion = (event, newValue) => {
        if (newValue) {
            setSelectedUnidadOtraActuacion(newValue);
            setOtrasActuacionesFitoData({ ...otrasActuacionesFitoData, unidad: newValue.codigo_siex });
        }
    };

    const handleEditOtra = async () => {
        try {
            const result = await api().patch(`/tratam_fitos/${id}/otra_actuacion_fito/update`, otrasActuacionesFitoData);

            if (result.status === 200) {
                alert("Otra actuación actualizado correctamente");
                fetchTratam();
                setError(null);
            } else {
                alert(`Error: ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        }
    };

    const handleNewOtra = async (e) => {
        e.preventDefault();
        try {
            const result = await api().post(`/tratam_fitos/${id}/otra_actuacion_fito/new`, otrasActuacionesFitoData);

            if (result.status === 201) {
                alert("Otra actuación creado correctamente");
                fetchTratam();
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        }
    };
    const handleDeleteOtra = async () => {
        try {
            const result = await api().delete(`/tratam_fitos/${id}/otra_actuacion_fito/delete`);

            if (result.status === 204) {
                alert("Otra actuación eliminado correctamente");
                fetchTratam();
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        }
    };

    return (
        <div>
            {tratam && tratam.otras_actuaciones_fito ? (
                <>
                    <Typography variant="h6" gutterBottom>Otra actuacion fitosanitaria</Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={tiposMedida || []}
                                getOptionLabel={(option) => option.codigo_siex?.toString() + "   " + option.tipo_medida?.toString() || ""}
                                value={selectedTipoMedida}
                                onChange={handleTipoMedida}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Tipo de Medida"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Cantidad"
                                name="cantidad"
                                value={otrasActuacionesFitoData.cantidad}
                                onChange={handleOtrasActFitoData}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={unidades || []}
                                getOptionLabel={(option) => option.codigo_siex?.toString() + "   " + option.unidades_medida?.toString() || ""}
                                value={selectedUnidadOtraActuacion}
                                onChange={handleUnidadOtraActuacion}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Unidad"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Numero de registro MDF"
                                name="num_registro_mdf"
                                value={otrasActuacionesFitoData.num_registro_mdf}
                                onChange={handleOtrasActFitoData}
                            />
                            <br />
                            <br />
                            <Button type="button" variant="contained" color="primary" onClick={handleEditOtra} style={{ marginRight: '10px' }}>
                                Editar actuación
                            </Button>
                            <Button type="button" variant="contained" color="error" onClick={handleDeleteOtra}>
                                Eliminar actuación
                            </Button>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Typography variant="h6" gutterBottom>Otra actuacion fitosanitaria</Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={tiposMedida || []}
                                getOptionLabel={(option) => option.codigo_siex?.toString() + "   " + option.tipo_medida?.toString() || ""}
                                value={selectedTipoMedida}
                                onChange={handleTipoMedida}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Tipo de Medida"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Cantidad"
                                name="cantidad"
                                value={otrasActuacionesFitoData.cantidad}
                                onChange={handleOtrasActFitoData}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                options={unidades || []}
                                getOptionLabel={(option) => option.codigo_siex?.toString() + "   " + option.unidades_medida?.toString() || ""}
                                value={selectedUnidadOtraActuacion}
                                onChange={handleUnidadOtraActuacion}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Unidad"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Numero de registro MDF"
                                name="num_registro_mdf"
                                value={otrasActuacionesFitoData.num_registro_mdf}
                                onChange={handleOtrasActFitoData}
                            />
                            <br />
                            <br />
                            <Button type="submit" variant="contained" color="primary" onClick={handleNewOtra}>
                                Crear Asesor
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}

            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    );
};

export default TratamFitoEditOtrasAct;
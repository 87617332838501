import React, { useState, useEffect } from "react";
import { Container, Autocomplete, TextField, Box, Typography } from "@mui/material";
import { api } from '../../login/OAuth';

function ProductoCultivo() {
  const [productosFito, setProductosFito] = useState(null);
  const [selectedProductoFito, setSelectedProductoFito] = useState(null);
  const [selectedCultivo, setSelectedCultivo] = useState(null);

  const [cultivos, setCultivos] = useState([]);

  useEffect(() => {
    const fetchProductosFito = async () => {
      try {
        const result = await api().get(`/catalogos/productos_fito`);
        setProductosFito(result.data);

      } catch (error) {
        console.error("Error fetching productos_fito:", error);
      }
    };
    fetchProductosFito();

  }, []);

  const handleProductoFito = (event, newValue) => {
    if (newValue) {
      setSelectedProductoFito(newValue);
      setSelectedCultivo(null);
      handleSubmit();
    }
  };

  const handleCultivo = (event, newValue) => {
    if (newValue) {
      setSelectedCultivo(newValue);
    }
  };

  const handleSubmit = async () => {
    if (selectedProductoFito) {
      try {
        const response = await api().get(`/productos_fito/${selectedProductoFito.numero_registro}/cultivos`);
        setCultivos(response.data);
      } catch (error) {
        console.error("Error calling /fitos/{num_registro}/cultivos:", error);
      } 
    }
  };


  return (
    <Container>
      <Box mt={3}>
        <Typography variant="h4" component="h1" gutterBottom>
          Cultivos compatibles con producto fitosanitario
        </Typography>
        <Typography variant="body1" paragraph>
          Elige el producto fitosanitario a comprobar
        </Typography>
        <Autocomplete
          options={productosFito}
          getOptionLabel={(option) => option.numero_registro?.toString() + "   " + option.nombre?.toString() || ""}
          value={selectedProductoFito}
          onChange={handleProductoFito}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Producto fitosanitario"
              fullWidth
            />
          )}
        />
        <br />
        {selectedProductoFito && cultivos.length > 0 && (

          <Autocomplete
            options={cultivos}
            getOptionLabel={(option) => option.codigo?.toString() + "   " + option.producto_siex?.toString() || ""}
            value={selectedCultivo}
            onChange={handleCultivo}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Cultivo"
                fullWidth
              />
            )}
          />
        )}
        {selectedProductoFito && cultivos.length === 0 && (
          <Typography variant="body1" paragraph>
          Este producto no tiene cultivos del catálogo SIEX asociado.

        </Typography>
        )}



      </Box>
    </Container>
  );
}

export default ProductoCultivo;
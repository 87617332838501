import React, { useState } from "react";
import { Button, Container, Box, Typography, CircularProgress } from "@mui/material";
import { api } from '../login/OAuth';

function MigrateCatalogos() {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);

            const result = await api().post('/migrate/catalogos');

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Catalogos migrados correctamente");
                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container>
            <Box mt={3}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Migrar catálogos
                </Typography>
                <Typography variant="body1" paragraph>
                    Al pulsar este botón se migrarán todos los catálogos del cue.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : "Migrar Ahora"}
                </Button>
                {loading && (
                    <Typography variant="body2" color="textSecondary">
                        Este proceso puede tardar unos minutos...
                    </Typography>
                )}
            </Box>
           
        </Container>
    );
}

export default MigrateCatalogos;

import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ProductosFitoTable = ({ productosFitoSchemas, onRemove }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Tipo de Cultivo</TableCell>
          <TableCell>Número de Registro</TableCell>
          <TableCell>Acciones</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {productosFitoSchemas.map((schema, index) => (
          <TableRow key={index}>
            <TableCell>{schema.tipo_producto}</TableCell>
            <TableCell>{schema.num_registro}</TableCell>
            <TableCell>
              <IconButton onClick={() => onRemove(index)}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ProductosFitoTable;

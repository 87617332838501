import './App.css';
import { HashRouter, Routes, Route } from 'react-router-dom';
import React from "react";
import Navbar from './components/Navbar';

import Home from './pages/Home'
import MigrateProducto from './pages/productos/MigrateProducto'
import MigrateSigpac from './pages/sigpac/MigrateSigpac';
import MigrateCatalogos from './pages/cue/MigrateCatalogos';
import ResetSigpac from './pages/sigpac/ResetSigpac';
import ResetProductos from './pages/productos/ResetProductos';
import ResetRegistros from './pages/registros/ResetRegistros';

import ListRegistros from './pages/registros/ListRegistros';

import Cuaderno from './pages/cue/Cuaderno';
import ExportarCue from './pages/cue/ExportarCue';
import ListarCuadernos from './pages/cue/ListarCuadernos';
import CuadernoDetails from './pages/cue/CuadernoDetails';
import DatosExplotacion from './pages/cue/DatosExplotacion';
import ActividadesExplotacion from './pages/cue/ActividadesExplotacion';

import TratamPostCosecha from './pages/cue/tratam_post_cosecha/TratamPostCosecha';
import TratamPostCosechaNew from './pages/cue/tratam_post_cosecha/TratamPostCosechaNew';
import TratamPostCosechaEditProblematica from './pages/cue/tratam_post_cosecha/TratamPostCosechaEditProblematica';
import TratamPostCosechaEditJustificaciones from './pages/cue/tratam_post_cosecha/TratamPostCosechaEditJustificaciones';
import TratamPostCosechaEditAsesores from './pages/cue/tratam_post_cosecha/TratamPostCosechaEditAsesores';
import TratamPostCosechaEditIdentAplicadores from './pages/cue/tratam_post_cosecha/TratamPostCosechaEditIdentAplicadores';
import TratamPostCosechaEditProductoFito from './pages/cue/tratam_post_cosecha/TratamPostCosechaEditProductoFito';
import TratamPostCosechaEdit from './pages/cue/tratam_post_cosecha/TratamPostCosechaEdit';

import TratamFito from './pages/cue/tratam_fito/TratamFito';
import TratamFitoNew from './pages/cue/tratam_fito/TratamFitoNew';
import TratamFitoEditProblematica from './pages/cue/tratam_fito/TratamFitoEditProblematica';
import TratamFitoEditJustificaciones from './pages/cue/tratam_fito/TratamFitoEditJustificaciones';
import TratamFitoEditDGCs from './pages/cue/tratam_fito/TratamFitoEditDGCs';
import TratamFitoEditIdentAplicadores from './pages/cue/tratam_fito/TratamFitoEditIdentAplicadores';
import TratamFitoEditProductoFito from './pages/cue/tratam_fito/TratamFitoEditProductoFito';
import TratamFitoEdit from './pages/cue/tratam_fito/TratamFitoEdit';

import Pastoreo from './pages/cue/pastoreo/Pastoreo';
import PastoreoEdit from './pages/cue/pastoreo/PastoreoEdit';
import PastoreoEditAnimales from './pages/cue/pastoreo/PastoreoEditAnimales';
import PastoreoEditDetAct from './pages/cue/pastoreo/PastoreoEditDetAct';
import PastoreoEditDGCs from './pages/cue/pastoreo/PastoreoEditDGCs';
import PastoreoNew from './pages/cue/pastoreo/PastoreoNew';
import TratamFitoEditAsesores from './pages/cue/tratam_fito/TratamFitoEditAsesores';
import TratamFitoEditOtrasAct from './pages/cue/tratam_fito/TratamFitoEditOtrasAct';
import ProductoCultivo from './pages/cue/productos_fito/ProductoCultivo';

function App() {
  return (
    <>
      <HashRouter>
        <Navbar />
        <Routes>
          <Route path='' exact element={<Home />} />
          <Route path='/migrate/productos' element={<MigrateProducto />} />
          <Route path='/migrate/sigpac' element={<MigrateSigpac />} />
          <Route path='/migrate/catalogos' element={<MigrateCatalogos />} />

          <Route path='/reset/sigpac' element={<ResetSigpac />} />
          <Route path='/reset/productos' element={<ResetProductos />} />
          <Route path='/reset/registros' element={<ResetRegistros />} />

          <Route path='/cuadernos/new' element={<Cuaderno />} />
          <Route path='/cuadernos/list' element={<ListarCuadernos />} />
          <Route path='/cuadernos/:id' element={<CuadernoDetails />} />
          <Route path='/cuadernos/:id/export' element={<ExportarCue />} />
          <Route path='/cuadernos/:id/datos-explotacion' element={<DatosExplotacion />} />
          <Route path='/cuadernos/:id/actividades-explotacion' element={<ActividadesExplotacion />} />

          <Route path='/cuadernos/:id/tratamientos_post_cosecha' element={<TratamPostCosecha />} />
          <Route path='/cuadernos/:id/tratamientos_post_cosecha/new' element={<TratamPostCosechaNew />} />
          <Route path='/tratam_post_cosecha/:id/edit/problematica' element={<TratamPostCosechaEditProblematica />} />
          <Route path='/tratam_post_cosecha/:id/edit/justificaciones' element={<TratamPostCosechaEditJustificaciones />} />
          <Route path='/tratam_post_cosecha/:id/edit/asesores' element={<TratamPostCosechaEditAsesores />} />
          <Route path='/tratam_post_cosecha/:id/edit/producto_fito' element={<TratamPostCosechaEditProductoFito />} />
          <Route path='/tratam_post_cosecha/:id/edit/aplicadores' element={<TratamPostCosechaEditIdentAplicadores />} />
          <Route path='/tratam_post_cosecha/:id/edit/' element={<TratamPostCosechaEdit />} />

          <Route path='/cuadernos/:id/tratamientos_fito' element={<TratamFito />} />
          <Route path='/cuadernos/:id/tratam_fito/new' element={<TratamFitoNew />} />
          <Route path='/tratam_fito/:id/edit/problematica' element={<TratamFitoEditProblematica />} />
          <Route path='/tratam_fito/:id/edit/justificaciones' element={<TratamFitoEditJustificaciones />} />
          <Route path='/tratam_fito/:id/edit/dgcs' element={<TratamFitoEditDGCs />} />
          <Route path='/tratam_fito/:id/edit/identificadores' element={<TratamFitoEditIdentAplicadores />} />
          <Route path='/tratam_fito/:id/edit/producto_fito' element={<TratamFitoEditProductoFito />} />
          <Route path='/tratam_fito/:id/edit/asesor' element={<TratamFitoEditAsesores />} />
          <Route path='/tratam_fito/:id/edit/otras_act' element={<TratamFitoEditOtrasAct />} />
          <Route path='/tratam_fito/:id/edit/' element={<TratamFitoEdit />} />

          <Route path='/cuadernos/:id/pastoreo' element={<Pastoreo />} />
          <Route path='/cuadernos/:id/pastoreo/new' element={<PastoreoNew />} />
          <Route path='/pastoreo/:id/edit' element={<PastoreoEdit />} />
          <Route path='/pastoreo/:id/edit/animales' element={<PastoreoEditAnimales />} />
          <Route path='/pastoreo/:id/edit/dgcs' element={<PastoreoEditDGCs />} />
          <Route path='/pastoreo/:id/edit/detalle_act' element={<PastoreoEditDetAct />} />

          <Route path='/registros/list' element={<ListRegistros />} />
          <Route path='/productos_fito/producto-cultivo' element={<ProductoCultivo />} />

          <Route path='*' exact element={<Home />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;

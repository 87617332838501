import React, { useState, useEffect, useCallback } from "react";
import { useParams } from 'react-router-dom';
import { api } from "../../login/OAuth";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Autocomplete,
    TextField,
    Typography
} from "@mui/material";

function TratamPostCosechaEditJustificaciones() {
    const { id } = useParams();
    const [justificaciones, setJustificaciones] = useState([]);
    const [justificacion, setJustificacion] = useState();
    const [loading, setLoading] = useState(false);
    const [tratam, setTratam] = useState("");
    const [error, setError] = useState(null);

    const fetchTratam = useCallback(async () => {
        try {
            const result = await api().get(`/tratamientos_post_cosecha/${id}`);
            setTratam(result.data);
        } catch (error) {
            console.error("Error fetching tratamiento:", error);
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        }
    }, [id]);

    useEffect(() => {
        const fetchJustificaciones = async () => {
            try {
                const result = await api().get("/catalogos/justificaciones");
                setJustificaciones(result.data);
            } catch (error) {
                console.error("Error fetching justificaciones:", error);
                setError(`Error: ${JSON.parse(error.request.response).detail}`);            }
        };
        fetchJustificaciones();
        fetchTratam();
    }, [id, fetchTratam]);



    const handleJustificacion = (event, newValue) => {
        if (newValue) {
            setJustificacion(newValue);
        }
    };

    const addJustificacion = async () => {
        try {
            setLoading(true);

            const result = await api().post(`/tratamiento_post/${id}/justificacion/add?justificacion=${justificacion.just_act}`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);
                } else {
                    fetchTratam();
                    setError(null)
                }
            } else {
                console.log(result);
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };
    const deleteJustificacion = async (justificacionId) => {
        try {
            setLoading(true);

            const result = await api().delete(`/tratamiento_post/${id}/justificacion/${justificacionId}/remove`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(`Error ${result.status}: ${result.data.detail}`);

                } else {
                    fetchTratam();
                    setError(null)
                }
            } else {
                setError(`Error: ${JSON.parse(error.request.response).detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {tratam && (
                <>
                    <Typography variant="h6" gutterBottom>Añadir Justificacion</Typography>

                    <Autocomplete
                        options={justificaciones}
                        getOptionLabel={(option) => option.just_act?.toString() + "   " + option.justificacion?.toString() || ""}
                        value={justificacion}
                        onChange={handleJustificacion}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Tipo de Justificacion"
                                fullWidth
                            />
                        )}
                    />
                    <br></br>

                    <Button variant="contained" color="success" onClick={addJustificacion} disabled={loading}>
                        Añadir Justificacion
                    </Button>

                    {tratam.justificaciones && (
                        <>
                            <Typography variant="h6" gutterBottom>Justificaciones Asociadas</Typography>

                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Codigo</TableCell>
                                            <TableCell>Justificacion</TableCell>
                                            <TableCell>Eliminar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tratam.justificaciones.map((justificacion) => (
                                            <TableRow key={justificacion.just_act}>
                                                <TableCell>{justificacion.just_act}</TableCell>
                                                <TableCell>{justificacion.justificacion}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        onClick={() => deleteJustificacion(justificacion.just_act)}
                                                        disabled={loading}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}

                </>

            )}
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    );
}

export default TratamPostCosechaEditJustificaciones;

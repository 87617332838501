import { Link , useLocation} from 'react-router-dom';
import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


function CuadernoDetails() {
    const location = useLocation();
    const cuaderno = location.state && location.state.cuaderno;

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Detalles Cuaderno
            </Typography>
            <Typography variant="body1" paragraph>
                <strong>Id del cuaderno:</strong> {cuaderno.id_cuaderno}
            </Typography>
            <Typography variant="body1" paragraph>
                <strong>Id del titular:</strong> {cuaderno.id_titular}
            </Typography>
            <Typography variant="body1" paragraph>
                <strong>Código SIEX:</strong> {cuaderno.codigo_siex}
            </Typography>
            <Typography variant="body1" paragraph>
                <strong>Código REA:</strong> {cuaderno.codigo_rea}
            </Typography>
            <Typography variant="body1" paragraph>
                <strong>Unidad Gestora:</strong> {cuaderno.unidad_gestora}
            </Typography>
            <Typography variant="body1" paragraph>
                <strong>Comunidad Autónoma:</strong> {cuaderno.ca_explotacion}
            </Typography>

            <br />

            <Link to={`/cuadernos/${cuaderno.id_cuaderno}/datos-explotacion`} state={{ cuaderno }}>
                <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                    Medidas preventivas
                </Button>
            </Link>

            <Link to={`/cuadernos/${cuaderno.id_cuaderno}/tratamientos_post_cosecha`}>
                <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                    Tratamientos post cosecha
                </Button>
            </Link>

            <Link to={`/cuadernos/${cuaderno.id_cuaderno}/tratamientos_fito`}>
                <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                    Tratamientos fito
                </Button>
            </Link>

            <Link to={`/cuadernos/${cuaderno.id_cuaderno}/pastoreo`}>
                <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                    Pastoreo
                </Button>
            </Link>
        </Box>
    )
}

export default CuadernoDetails
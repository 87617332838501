import React from "react";
import Cards from "../components/Cards";

export default function Home() {
  return (
    <div>
        <Cards />

    </div>
  );
}

import React, { useState, useEffect,useCallback } from "react";
import { useParams } from 'react-router-dom';
import { api } from "../../login/OAuth";
import DateTimePickerComponent from "../../../components/DateTimePickerComponent";

import { TextField, Button, Grid, Typography, Autocomplete, Input, InputLabel } from "@mui/material";


const TratamPostCosechaEdit = () => {
    const { id } = useParams();
    const [tratam, setTratam] = useState(null);
    const [eficacias, setEficacias] = useState(null);
    const [selectedEficacia, setSelectedEficacia] = useState(null);
    const [error, setError] = useState(null);

    const [tratamientoData, setTratamientoData] = useState({
        id_ajena_tratam_fito: null,
        fecha_inicio: null,
        fecha_fin: null,
        fecha_seca: null,
        eficacia: null,
        observaciones: null,
    });

    const fetchTratam = useCallback(async () => {
        try {
            const resultE = await api().get(`/catalogos/eficacias`);
            setEficacias(resultE.data);

            const result = await api().get(`/tratam_fitos/${id}`);
            setTratam(result.data);

            if (result.data) {
                setTratamientoData(result.data);

                const selectedEficacia = resultE.data.find(eficacia => eficacia.codigo_siex === result.data.eficacia);
                if (selectedEficacia) {
                    setSelectedEficacia(selectedEficacia);
                } else {
                    console.error("Error: Eficacia no encontrada");
                }
            }
        } catch (error) {
            console.error("Error fetching tratamiento:", error);
        }
    },[id]);

    useEffect(() => {
        fetchTratam();
    }, [id,fetchTratam]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTratamientoData({ ...tratamientoData, [name]: value });
    };
    const handleEficacia = (event, newValue) => {
        if (newValue) {
            setSelectedEficacia(newValue);
            setTratamientoData({ ...tratamientoData, eficacia: newValue.codigo_siex });
        }
    };
    const handleFechaInicioChange = (e) => {
        setTratamientoData({ ...tratamientoData, ['fecha_inicio']: e });
    };
    const handleFechaFinChange = (e) => {
        setTratamientoData({ ...tratamientoData, ['fecha_fin']: e });
    };
    const handleEditTratamiento = async () => {
        try {
            const result = await api().patch(`/tratam_fitos/${id}/update`, tratamientoData);

            if (result.status === 204) {
                alert("Tratamiento actualizado correctamente");
                fetchTratam();
                setError(null)
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        }
    };

    return (
        <div>
            {tratam ? (
                <Grid>
                    <Typography variant="h6" gutterBottom>
                        Editar tratamiento
                    </Typography>
                    <form onSubmit={handleEditTratamiento}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="ID Ajena Tratamiento Fito"
                                    name="id_ajena_tratam_fito"
                                    value={tratamientoData.id_ajena_tratam_fito}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DateTimePickerComponent
                                    required
                                    fullWidth
                                    label={"Fecha de Inicio"}
                                    value={tratamientoData.fecha_inicio}
                                    name="fecha_inicio"
                                    onChange={handleFechaInicioChange}

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DateTimePickerComponent
                                    required
                                    fullWidth
                                    label={"Fecha de Fin"}
                                    value={tratamientoData.fecha_fin}
                                    name="fecha_fin"
                                    onChange={handleFechaFinChange}

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="fecha_seca">Fecha de Seca</InputLabel>
                                <Input
                                    id="fecha_seca"
                                    type="date"
                                    name="fecha_seca"
                                    value={tratamientoData.fecha_seca}
                                    onChange={handleInputChange}
                                    fullWidth
                                    required
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={eficacias}
                                    getOptionLabel={(option) => option.codigo_siex?.toString() + "   " + option.eficacia_tratamiento?.toString() || ""}
                                    value={selectedEficacia}
                                    onChange={handleEficacia}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Eficacia"
                                            fullWidth
                                            margin="normal"

                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Observaciones"
                                    multiline
                                    rows={4}
                                    name="observaciones"
                                    value={tratamientoData.observaciones}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                        </Grid>
                        <br />
                        <Button type="button" variant="contained" color="primary" onClick={handleEditTratamiento}>
                            Guardar
                        </Button>
                    </form>

                </Grid>
            ) : (
                <>cargando</>
            )
            }
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div >
    );
};

export default TratamPostCosechaEdit;
import React, { useState, useEffect,useCallback } from "react";
import { useParams } from 'react-router-dom';
import { api } from "../../login/OAuth";

import { TextField, Button, Typography, Paper, Checkbox, FormControlLabel } from "@mui/material";


const TratamFitoEditAsesores = () => {
    const { id } = useParams();
    const [tratam, setTratam] = useState(null);
    const [error, setError] = useState(null);
    const [asesorData, setAsesorData] = useState({
        num_ropo: "",
        fecha: null,
        validacion: null,
        observaciones: null,
        confirmacion: null,
        contrato: null,
    });

    const fetchTratam = useCallback(async () => {
        try {
            const result = await api().get(`/tratam_fitos/${id}`);
            setTratam(result.data);
            if (result.data.asesor_validacion) {
                setAsesorData(result.data.asesor_validacion);
            }
        } catch (error) {
            console.error("Error fetching tratamiento:", error);
        }
    },[id]);

    useEffect(() => {

        fetchTratam();
    }, [id,fetchTratam]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === "checkbox" ? checked : value;

        setAsesorData({
            ...asesorData,
            [name]: inputValue,
        });
    };

    const handleEditAsesor = async () => {
        try {
            const result = await api().patch(`/tratam_fitos/${id}/asesor_validacion/update`, asesorData);

            if (result.status === 200) {
                alert("Asesor actualizado correctamente");
                fetchTratam();
                setError(null);
            } else {
                alert(`Error: ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        }
    };

    const handleNewAsesor = async (e) => {
        e.preventDefault();
        try {
            const result = await api().post(`/tratam_fitos/${id}/asesor_validacion/new`, asesorData);

            if (result.status === 201) {
                alert("Asesor creado correctamente");
                fetchTratam();
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        }
    };
    const handleDeleteAsesor = async () => {
        try {
            const result = await api().delete(`/tratam_fitos/${id}/asesor_validacion/delete`);

            if (result.status === 204) {
                alert("Asesor eliminado correctamente");
                fetchTratam();
                setError(null);
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);        }
    };

    return (
        <div>
            {tratam && tratam.asesor_validacion ? (
                <>
                    <Typography variant="h6" gutterBottom>Detalles del Asesor de Validación</Typography>

                    <TextField
                        fullWidth
                        label="Número Ropo"
                        name="num_ropo"
                        value={asesorData.num_ropo}
                        onChange={handleInputChange}
                        margin="normal"
                        required
                    />
                    <TextField
                        fullWidth
                        name="fecha"
                        type="date"
                        value={asesorData.fecha}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Observaciones"
                        name="observaciones"
                        multiline
                        rows={3}
                        value={asesorData.observaciones}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={asesorData.validacion || false} onChange={handleInputChange} name="validacion" />}
                        label="Validacion"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={asesorData.confirmacion || false} onChange={handleInputChange} name="confirmacion" />}
                        label="Confirmación"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={asesorData.contrato || false} onChange={handleInputChange} name="contrato" />}
                        label="Contrato"
                    />
                    <br />
                    <br />
                    <Button type="button" variant="contained" color="primary" onClick={handleEditAsesor} style={{ marginRight: '10px' }}>
                        Editar Asesor
                    </Button>
                    <Button type="button" variant="contained" color="error" onClick={handleDeleteAsesor}>
                        Eliminar Asesor
                    </Button>
                </>
            ) : (
                <Paper elevation={3} style={{ padding: 20, maxWidth: 400, margin: "auto", marginTop: 20 }}>
                    <Typography variant="h6" gutterBottom>
                        Nuevo Asesor de Validación
                    </Typography>
                    <form onSubmit={handleNewAsesor}>
                        <TextField
                            fullWidth
                            label="Número Ropo"
                            name="num_ropo"
                            value={asesorData.num_ropo}
                            onChange={handleInputChange}
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            name="fecha"
                            type="date"
                            value={asesorData.fecha}
                            onChange={handleInputChange}
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Observaciones"
                            name="observaciones"
                            multiline
                            rows={3}
                            value={asesorData.observaciones}
                            onChange={handleInputChange}
                            margin="normal"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={asesorData.validacion || false} onChange={handleInputChange} name="validacion" />}
                            label="Validacion"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={asesorData.confirmacion || false} onChange={handleInputChange} name="confirmacion" />}
                            label="Confirmación"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={asesorData.contrato || false} onChange={handleInputChange} name="contrato" />}
                            label="Contrato"
                        />
                        <Button type="submit" variant="contained" color="primary">
                            Crear Asesor
                        </Button>
                    </form>
                </Paper>
            )}
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </div>
    );
};

export default TratamFitoEditAsesores;
import React, { useState } from "react";
import { Button, Container, TextField, Box, Typography, Select, MenuItem, CircularProgress } from "@mui/material";
import { api } from '../login/OAuth';

function Sigpac() {
  const [loading, setLoading] = useState(false);
  const [tipoExtension, setTipoExtension] = useState("provincias");
  const [modoRecursivo, setModoRecursivo] = useState("");
  const [codigoComunidad, setCodigoComunidad] = useState("");
  const [codigoProvincia, setCodigoProvincia] = useState("");
  const [codigoMunicipio, setCodigoMunicipio] = useState("");
  const [codigoPoligono, setCodigoPoligono] = useState("");
  const [codigoParcela, setCodigoParcela] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const result = await api().post(`/migrate/${tipoExtension}/${modoRecursivo
                                                                  }?codigo_comunidad=${codigoComunidad
                                                                  }&codigo_provincia=${codigoProvincia
                                                                  }&codigo_municipio=${codigoMunicipio
                                                                  }&codigo_poligono=${codigoPoligono
                                                                  }&codigo_parcela=${codigoParcela}`);
      if (result.status === 200) {
        if (result.data != null && result.data.status_code >= 300) {
          alert(`Error: ${result.data.detail}`);
        } else {
          alert("Extensiones migradas correctamente");
        }
      } else {
        alert(`Error ${result.data.detail}`);
      }
      setCodigoComunidad("");
      setCodigoProvincia("");
      setCodigoMunicipio("");
      setCodigoPoligono("");
      setCodigoParcela("");
      setModoRecursivo("");
    } catch (error) {
      alert("Error", error);
    } finally {
      setLoading(false);

    }
  };

  const handleNumberInputChange = (value, setterFunction) => {
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      setterFunction(value);
    }
    else {
      setterFunction(null);
    }
  };

  return (
    <Container>
      <Box mt={3}>
        <Typography variant="h4" component="h1" gutterBottom>
          Migrar Sigpac
        </Typography>
        <Typography variant="body1" paragraph>
          Elige qué tipo de extensión quieres migrar.
        </Typography>
        <Select
          value={tipoExtension}
          onChange={(e) => setTipoExtension(e.target.value)}
        >
          <MenuItem value="comunidades-provincias">Comunidades y Provincias</MenuItem>
          <MenuItem value="provincias">Provincias</MenuItem>
          <MenuItem value="municipios">Municipios</MenuItem>
          <MenuItem value="poligonos">Polígonos</MenuItem>
          <MenuItem value="parcelas">Parcelas</MenuItem>
          <MenuItem value="recintos">Recintos</MenuItem>
        </Select>

        {tipoExtension === "municipios" && (
          <Select
            value={modoRecursivo}
            onChange={(e) => setModoRecursivo(e.target.value)}
          >
            <MenuItem value="">No Recursivo</MenuItem>
            <MenuItem value="rec">Recursivo</MenuItem>
          </Select>
        )}

        {(tipoExtension === "provincias") && (
          <TextField
            label="Código Comunidad Autónoma"
            required
            type="number"
            value={codigoComunidad}
            onChange={(e) =>
              handleNumberInputChange(e.target.value, setCodigoComunidad)
            }
          />
        )}

        {(tipoExtension === "municipios") && (
          <TextField
            label="Código Provincia"
            required
            type="number"
            value={codigoProvincia}
            onChange={(e) =>
              handleNumberInputChange(e.target.value, setCodigoProvincia)
            }
          />
        )}

        {tipoExtension === "poligonos" && (
          <>
            <Select
              value={modoRecursivo}
              onChange={(e) => setModoRecursivo(e.target.value)}
            >
              <MenuItem value="">No Recursivo</MenuItem>
              <MenuItem value="rec">Recursivo</MenuItem>
            </Select>
            <TextField
              label="Código Provincia"
              required
              type="number"
              value={codigoProvincia}
              onChange={(e) =>
                handleNumberInputChange(e.target.value, setCodigoProvincia)
              }
            />
            <TextField
              label="Código Municipio"
              required
              type="number"
              value={codigoMunicipio}
              onChange={(e) =>
                handleNumberInputChange(e.target.value, setCodigoMunicipio)
              }
            />
          </>
        )}

        {tipoExtension === "parcelas" && (
          <>
            <Select
              value={modoRecursivo}
              onChange={(e) => setModoRecursivo(e.target.value)}
            >
              <MenuItem value="">No Recursivo</MenuItem>
              <MenuItem value="rec">Recursivo</MenuItem>
            </Select>
            <TextField
              label="Código Provincia"
              required
              type="number"
              value={codigoProvincia}
              onChange={(e) =>
                handleNumberInputChange(e.target.value, setCodigoProvincia)
              }
            />
            <TextField
              label="Código Municipio"
              required
              type="number"
              value={codigoMunicipio}
              onChange={(e) =>
                handleNumberInputChange(e.target.value, setCodigoMunicipio)
              }
            />
            <TextField
              label="Código Polígono"
              required
              type="number"
              value={codigoPoligono}
              onChange={(e) =>
                handleNumberInputChange(e.target.value, setCodigoPoligono)
              }
            />
          </>
        )}

        {tipoExtension === "recintos" && (
          <>
            <TextField
              label="Código Provincia"
              required
              type="number"
              value={codigoProvincia}
              onChange={(e) =>
                handleNumberInputChange(e.target.value, setCodigoProvincia)
              }
            />
            <TextField
              label="Código Municipio"
              required
              type="number"
              value={codigoMunicipio}
              onChange={(e) =>
                handleNumberInputChange(e.target.value, setCodigoMunicipio)
              }
            />
            <TextField
              label="Código Polígono"
              required
              type="number"
              value={codigoPoligono}
              onChange={(e) =>
                handleNumberInputChange(e.target.value, setCodigoPoligono)
              }
            />
            <TextField
              label="Código Parcela"
              required
              type="number"
              value={codigoParcela}
              onChange={(e) =>
                handleNumberInputChange(e.target.value, setCodigoParcela)
              }
            />
          </>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Migrar Ahora"}
        </Button>
        {loading && (
          <Typography variant="body2" color="textSecondary">
            Este proceso puede tardar unos minutos...
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default Sigpac;
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const api = () => {
    try{
        console.log(apiUrl);
        return axios.create({
            baseURL: apiUrl,
            headers: {
                'Content-Type': 'application/json',
            },
        });

    }catch (error) {
        console.log(error);

    }
};
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { api } from "../../login/OAuth";

import {
    Button,
    TextField,
    Grid,
    Paper,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Autocomplete,
    Modal,
    Typography,
    Input,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from "@mui/material";

function TratamPostCosechaNew() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [identificadorModalOpen, setIdentificadorModalOpen] = useState(false);
    const [identificadores, setIdentificadores] = useState([]);
    const [error, setError] = useState(null);
    const [numeroRopo, setNumeroRopo] = useState('');
    const [aplicadorEmpresaId, setAplicadorEmpresaId] = useState("");
    const [equipoAplicadorId, setEquipoAplicadorId] = useState("");
    const [aplicadores, setAplicadores] = useState([]);
    const [equipos, setEquipos] = useState([]);
    const [selectedProductoField, setSelectedProductoField] = useState("dosis");
    const [selectedAttribute, setSelectedAttribute] = useState("");
    const [unidades, setUnidades] = useState(null);
    const [selectedUnidad, setSelectedUnidad] = useState(null);

    const [productosVegetales, setProductosVegetales] = useState(null);
    const [selectedProductoVegetal, setSelectedProductoVegetal] = useState(null);

    const [cultivosNoComunes, setCultivosNoComunes] = useState([]);
    const [selectedCultivoNoComun, setSelectedCultivoNoComun] = useState(null);

    const [productos, setProductos] = useState([]);
    const [selectedProducto, setSelectedProducto] = useState(null);

    const [eficacias, setEficacias] = useState(null);
    const [selectedEficacia, setSelectedEficacia] = useState(null);

    const [abierto, setAbierto] = useState(false);
    const [productoInfo, setProductoInfo] = useState(null);

    const [selectedUso, setSelectedUso] = useState(null);

    const [equipoValues, setEquipoValues] = useState({
        numeroRoma: null,
        numeroReganip: null,
        equipoPropio: false,
        idEquipoAplicador: null,
    });

    const navigate = useNavigate();

    useEffect(() => {

        const fetchUnidades = async () => {
            try {
                const result = await api().get(`/catalogos/unidades`);
                setUnidades(result.data);

            } catch (error) {
                console.error("Error fetching unidades:", error);
            }
        };

        const fetchProductosVegetales = async () => {
            try {
                const result = await api().get(`/catalogos/prod_vegetales`);
                setProductosVegetales(result.data);

            } catch (error) {
                console.error("Error fetching productos_veg:", error);
            }
        };
        const fetchEficacias = async () => {
            try {
                const result = await api().get(`/catalogos/eficacias`);
                setEficacias(result.data);

            } catch (error) {
                console.error("Error fetching eficacias:", error);
            }
        };
        fetchAplicadores();
        fetchEquipos();
        fetchIdentificadores();
        fetchUnidades();
        fetchProductosVegetales();
        fetchNoComunes();
        fetchEficacias();
    }, []);

    const fetchProductosFito = async () => {
        try {
            const result = await api().get(`/productos_fito/${selectedProductoVegetal.producto_siex}/num_registro`);
            if (result.data.length == 0) {
                setAbierto(true);
                setProductos([]);
                setSelectedProducto(null);

            } else {
                setSelectedProducto(null);
                setProductos(result.data);
                setAbierto(false);
            }

        } catch (error) {
            console.error("Error fetching productos_fito:", error);
        }
    };

    useEffect(() => {
        if (selectedProductoVegetal) {
            setSelectedCultivoNoComun(null);
            fetchProductosFito();
        }
    }, [selectedProductoVegetal]);

    const fetchNoComunes = async () => {
        try {
            const result = await api().get(`/cultivos_no_comunes`);
            setCultivosNoComunes(result.data);

        } catch (error) {
            console.error("Error fetching productos_fito:", error);
        }
    };

    useEffect(() => {
        if (selectedCultivoNoComun) {
            fetchProductosFitoNC();
        }
    }, [selectedCultivoNoComun]);

    const fetchProductosFitoNC = async () => {
        try {
            const result = await api().get(`/productos_fito/${selectedCultivoNoComun}/productos`);
            setProductos(result.data);

        } catch (error) {
            console.error("Error fetching productos_fito:", error);
        }
    };

    const fetchDetallesProductoUso = async () => {
        try {
            let selectedCultivoValue = selectedCultivoNoComun ? selectedCultivoNoComun : selectedProductoVegetal.producto_siex;
            const result = await api().get(`/productos_fito/${selectedCultivoValue}/${selectedProducto.numero_registro}`);
            setProductoInfo(result.data);
            console.log(result)
        } catch (error) {
            console.error("Error fetching productos_fito:", error);
        }
    };

    useEffect(() => {
        if (selectedProducto) {
            fetchDetallesProductoUso();
        }
    }, [selectedProducto]);

    const handleChangeUso = (uso) => {
        setSelectedUso(uso);
        setFormData({ ...formData, id_uso: uso });
    };

    useEffect(() => {
        if (selectedProductoVegetal == null) {
            setAbierto(false);
            setProductos([]);
            setSelectedProducto(null);
            setProductoInfo(null);
            setSelectedCultivoNoComun(null);
            setSelectedUnidad(null);
            setError(null);
            setSelectedUso(null);
        }
    }, [selectedProductoVegetal]);

    const [formData, setFormData] = useState({
        id_ajena_tratam_postco: "",
        fecha_actuacion: "",
        producto_vegetal: "",
        cantidad_tra: "",
        eficacia: "",
        observaciones: "",
        num_registro: "",
        dosis: null,
        cantidad: null,
        id_uso: null,
        unidad: "",
        identificadores_ids: [],
    });

    const fetchIdentificadores = async () => {
        try {
            const result = await api().get("/identificadores");
            setIdentificadores(result.data);
        } catch (error) {
            console.error("Error fetching identificadores:", error);
        }
    };

    const fetchAplicadores = async () => {
        try {
            const result = await api().get("/aplicadores");
            setAplicadores(result.data);
        } catch (error) {
            console.error("Error fetching aplicadores:", error);
            setError("Error al cargar aplicadores");
        }
    };

    const fetchEquipos = async () => {
        try {
            const result = await api().get("/equipos_aplicadores");
            console.log(result.data)
            setEquipos(result.data);
        } catch (error) {
            console.error("Error fetching equipos:", error);
            setError("Error al cargar equipos");
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleUnidad = (event, newValue) => {
        if (newValue) {
            setSelectedUnidad(newValue);
            setFormData({ ...formData, unidad: newValue.codigo_siex });
        }
    };
    const handleProductoFito = (event, newValue) => {
        if (newValue) {
            setSelectedProducto(newValue);
            setFormData({ ...formData, num_registro: newValue.numero_registro });
        }
    };
    const handleProductoVegetales = (event, newValue) => {
        if (newValue) {
            setSelectedProductoVegetal(newValue);
            setFormData({ ...formData, producto_vegetal: newValue.codigo_siex });
        }
    };
    const handleEficacia = (event, newValue) => {
        if (newValue) {
            setSelectedEficacia(newValue);
            setFormData({ ...formData, eficacia: newValue.codigo_siex });
        }
    };

    const handleIdentificadoresChange = (event) => {
        const selectedIdentificadores = event.target.value;
        setFormData({ ...formData, identificadores_ids: selectedIdentificadores });
    };


    const createIdentificadorEmpresa = async (aplicadorEmpresaId, equipoAplicadorId) => {
        try {
            setLoading(true);

            const url = `/identificadores_aplicadores/new?aplicador_empresa_id=${aplicadorEmpresaId}&equipo_aplicador_id=${equipoAplicadorId}`;

            const result = await api().post(url);

            if (result.status < 300) {
                if (result.data && result.data.status_code >= 300) {
                    setError(result.data.detail);
                } else {
                    setError(null);
                    alert("IdentificadorEmpresa creado correctamente");
                    fetchIdentificadores();
                    closeIdentificadorModal();
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };

    const createTratam = async (event) => {
        event.preventDefault();
        try {

            if (selectedUso == null) {
                setError("Seleccione un uso");
                return;
            }

            setLoading(true);
            console.log(formData.producto_vegetal)
            const result = await api().post(`/cuadernos/${id}/tratamientos_post_cosecha/new`, {
                cuaderno_id: parseInt(id),
                tr_post_cosecha_data: {
                    id_ajena_tratam_postco: parseInt(formData.id_ajena_tratam_postco),
                    fecha_actuacion: formData.fecha_actuacion,
                    producto_vegetal: parseInt(formData.producto_vegetal),
                    cantidad: parseFloat(formData.cantidad_tra),
                    eficacia: parseInt(formData.eficacia),
                    observaciones: formData.observaciones,
                },
                producto_fito_data: {
                    num_registro: formData.num_registro,
                    dosis: parseFloat(formData.dosis),
                    cantidad: parseFloat(formData.cantidad),
                    unidad: parseInt(formData.unidad),
                    id_uso: formData.id_uso,
                },
                identificadores_aplicadores_ids: formData.identificadores_ids,
            });

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError(result.data.detail);

                } else {
                    alert("Tratamiento creado correctamente");
                    navigate(`/cuadernos/${id}/tratamientos_post_cosecha`);
                }
            } else {
                setError(`Error: ${JSON.parse(error.request.response).detail}`);


            }
        } catch (error) {
            setError(`Error: ${error.response.data.detail}`);

        } finally {
            setLoading(false);
        }
    };

    const createAplicadorEmpresa = async (num_ropo) => {
        try {
            setLoading(true);

            const result = await api().post(`/aplicadores_empresa/new?num_ropo=${num_ropo}`);

            if (result.status < 300) {
                if (result.data && result.data.status_code >= 300) {
                    setError(result.data.detail);
                } else {
                    setError(null);
                    alert("AplicadorEmpresa creado correctamente");
                    fetchAplicadores();
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };

    const createEquipoAplicador = async () => {
        try {
            setLoading(true);
            console.log(equipoValues)
            const result = await api().post('/equipos_aplicadores/new', {
                num_roma: equipoValues.numeroRoma,
                num_reganip: equipoValues.numeroReganip,
                equipo_propio: equipoValues.equipoPropio,
                id_equipo_aplicador: equipoValues.idEquipoAplicador
            });

            if (result.status < 300) {
                if (result.data && result.data.status_code >= 300) {
                    setError(result.data.detail);
                } else {
                    setError(null);
                    alert("EquipoAplicador creado correctamente");
                    fetchEquipos();
                }
            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        } finally {
            setLoading(false);
        }
    };

    const handleFieldProductoChange = (e) => {
        setSelectedProductoField(e.target.value);
    };

    const openIdentificadorModal = () => {
        setIdentificadorModalOpen(true);
    };
    const closeIdentificadorModal = () => {
        setIdentificadorModalOpen(false);
    };


    return (
        <Paper elevation={3} style={{ padding: 20 }}>
            <Typography component="h2" variant="h5">
                Nuevo tratamiento post cosecha
            </Typography>
            <br></br>
            <form onSubmit={createTratam}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            label="ID Ajena Tratamiento Post Cosecha"
                            name="id_ajena_tratam_postco"
                            value={formData.id_ajena_tratam_postco}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>

                        <InputLabel htmlFor="fecha_actuacion">Fecha Actuación</InputLabel>
                        <Input
                            id="fecha_actuacion"
                            type="date"
                            name="fecha_actuacion"
                            value={formData.fecha_actuacion}
                            onChange={handleChange}
                            fullWidth
                            required
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel id="identificadores-label">Identificadores de Aplicadores</InputLabel>
                        <Select
                            labelId="identificadores-label"
                            id="identificadores"
                            multiple
                            fullWidth
                            value={formData.identificadores_ids}
                            onChange={handleIdentificadoresChange}
                        >
                            {identificadores.map((identificador) => (
                                <MenuItem key={identificador.id} value={identificador.id}>
                                    {"Aplicador:" + identificador.aplicador_empresa.num_ropo + "  //  Equipo:" + identificador.equipo_aplicador_tratam.id}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <Button variant="outlined" color="primary" onClick={openIdentificadorModal}>
                            Crear nuevo identificador
                        </Button>
                    </Grid>

                    <Grid item xs={12}>

                        <Autocomplete
                            options={productosVegetales}
                            getOptionLabel={(option) => option.codigo_siex?.toString() + "   " + option.producto?.toString() + " - " + option.producto_siex?.toString() || ""}
                            value={selectedProductoVegetal}
                            onChange={handleProductoVegetales}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Producto vegetal"
                                    fullWidth
                                    required
                                    margin="normal"

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {cultivosNoComunes && abierto && (
                            <>
                                <Typography variant="body1" paragraph>
                                    El cultivo seleccionado no coincide con ningún uso fitosanitario. Selecciona un cultivo similar.
                                </Typography>
                                <Autocomplete
                                    options={cultivosNoComunes}
                                    getOptionLabel={(cultivo) => cultivo}
                                    style={{ width: 400, marginTop: 20 }}
                                    onChange={(event, newValue) => setSelectedCultivoNoComun(newValue)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Selecciona un cultivo similar" />
                                    )}
                                />
                            </>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            label="Cantidad (kg)"
                            helperText="Max: 999999,99 kg"
                            name="cantidad_tra"
                            value={formData.cantidad_tra}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {selectedProductoVegetal && productos && (

                            <>
                                <Typography component="h2" variant="h5">
                                    Producto utilizado
                                </Typography>
                                <br></br>
                                <Grid item xs={12}>

                                    <Autocomplete
                                        options={productos}
                                        getOptionLabel={(option) => option.numero_registro?.toString() + "   " + option.nombre?.toString() || ""}
                                        value={selectedProducto}
                                        onChange={handleProductoFito}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Producto fitosanitario"
                                                fullWidth
                                                required
                                                margin="normal"

                                            />
                                        )}
                                    />
                                </Grid>
                            </>
                        )}
                        {selectedProducto && productoInfo && (
                            <Grid item xs={12}>
                                <Box margin="2rem">
                                    <TableContainer component={Paper}>
                                        <Typography variant="h5" component="h5" gutterBottom>
                                            Producto fitosanitario
                                        </Typography>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>SIEX ID</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Nombre</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Estado</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Nº Registro</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Formulado</Typography>

                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow key={productoInfo.id}>
                                                    <TableCell component="th" scope="row">
                                                        {productoInfo.siex_id}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {productoInfo.nombre}
                                                    </TableCell>
                                                    <TableCell>
                                                        {productoInfo.siex_estado}

                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {productoInfo.numero_registro}

                                                    </TableCell>
                                                    <TableCell>
                                                        {productoInfo.formulado}
                                                    </TableCell>
                                                </TableRow>


                                            </TableBody>
                                        </Table>
                                        <br />

                                        <Typography variant="h5" component="h5" gutterBottom>
                                            Usos
                                        </Typography>

                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Cultivo</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Agentes</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Dosis MIN</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Dosis MAX</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Ud. Dosis</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Volumen MIN</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Volumen MAX</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Ud. Volumen</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Aplicaciones</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Intervalo de aplicaciones</Typography>

                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" gutterBottom>Seleccionar</Typography>

                                                    </TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {productoInfo.usos.map(uso => (

                                                    <TableRow key={uso.id}>
                                                        <TableCell component="th" scope="row">
                                                            {uso.cultivo || "No especificado"}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {uso.agente || "No especificado"}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {uso.dosis_min || "No especificado"}

                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {uso.dosis_max || "No especificado"}

                                                        </TableCell>
                                                        <TableCell component="th" scope="row">

                                                            {uso.unidad_dosis || "No especificado"}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">

                                                            {uso.vol_min || "No especificado"}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">

                                                            {uso.vol_max || "No especificado"}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">

                                                            {uso.unidad_vol || "No especificado"}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">

                                                            {uso.aplicaciones || "No especificado"}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">

                                                            {uso.intervalo_aplicaciones || "No especificado"}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <FormControlLabel
                                                                key={uso.id}
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedUso === uso.id}
                                                                        onChange={() => handleChangeUso(uso.id)}
                                                                    />
                                                                }
                                                            />
                                                        </TableCell>

                                                    </TableRow>

                                                ))}
                                            </TableBody>
                                        </Table>

                                    </TableContainer>
                                </Box>

                                <FormControl fullWidth>
                                    <InputLabel id="select-field-label">Seleccionar Campo</InputLabel>
                                    <Select
                                        labelId="select-field-label"
                                        id="select-field"
                                        value={selectedProductoField}
                                        onChange={handleFieldProductoChange}
                                    >
                                        <MenuItem value="dosis">Dosis</MenuItem>
                                        <MenuItem value="cantidad">Cantidad</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    required
                                    name={selectedProductoField}
                                    value={formData[selectedProductoField]}
                                    onChange={handleChange}
                                    margin="normal"

                                />

                                <Grid item xs={12}>

                                    <Autocomplete
                                        options={unidades}
                                        getOptionLabel={(option) => option.codigo_siex?.toString() + "   " + option.unidades_medida?.toString() || ""}
                                        value={selectedUnidad}
                                        onChange={handleUnidad}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Unidad"
                                                fullWidth
                                                required
                                                margin="normal"

                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>



                    <Grid item xs={12}>
                        <Typography variant="h5" component="h5" gutterBottom>
                            Datos opcionales
                        </Typography>

                        <Autocomplete
                            options={eficacias}
                            getOptionLabel={(option) => option.codigo_siex?.toString() + "   " + option.eficacia_tratamiento?.toString() || ""}
                            value={selectedEficacia}
                            onChange={handleEficacia}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Eficacia"
                                    fullWidth
                                    margin="normal"

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Observaciones"
                            multiline
                            rows={4}
                            name="observaciones"
                            value={formData.observaciones}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Modal open={identificadorModalOpen} onClose={closeIdentificadorModal}>
                        <Paper elevation={3} style={{ padding: 20, maxWidth: 400, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <h1>Crear Identificador</h1>
                            <Grid item xs={12}>
                                <h3>Crear Aplicador Empresa</h3>
                                <TextField
                                    label="Número Ropo"
                                    onChange={(e) => setNumeroRopo(e.target.value)}
                                    fullWidth
                                    required
                                    margin="normal"
                                />
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => createAplicadorEmpresa(numeroRopo)}
                                    disabled={loading}
                                >
                                    Crear Aplicador Empresa
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <h3>Crear Equipo Aplicador</h3>
                                <FormControl fullWidth>
                                    <InputLabel>Atributo</InputLabel>
                                    <Select
                                        label="Atributo"
                                        value={selectedAttribute}
                                        onChange={(e) => setSelectedAttribute(e.target.value)}
                                    >
                                        <MenuItem value="numeroRoma">Número Roma</MenuItem>
                                        <MenuItem value="numeroReganip">Número Reganip</MenuItem>
                                        <MenuItem value="idEquipoAplicador">ID Equipo Aplicador</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label={selectedAttribute === "idEquipoAplicador" ? "ID Equipo Aplicador" : `Número ${selectedAttribute}`}
                                    onChange={(e) => {
                                        let newValues = Object.keys(equipoValues).reduce((obj, key) => {
                                            obj[key] = null;
                                            return obj;
                                        }, {});

                                        newValues[selectedAttribute] = e.target.value;

                                        setEquipoValues(newValues);
                                    }}
                                    fullWidth
                                    required
                                    margin="normal"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={equipoValues.equipoPropio}
                                            onChange={(e) => setEquipoValues({ ...equipoValues, equipoPropio: e.target.checked })}
                                        />
                                    }
                                    label="Equipo Propio"
                                />
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={createEquipoAplicador}
                                    disabled={loading}
                                >
                                    Crear Equipo Aplicador
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <h3>Crear Identificador Empresa</h3>
                                <FormControl fullWidth>
                                    <InputLabel>Aplicador Empresa</InputLabel>
                                    <Select
                                        label="Aplicador Empresa"
                                        onChange={(e) => setAplicadorEmpresaId(e.target.value)}
                                    >
                                        {aplicadores.map((aplicador) => (
                                            <MenuItem key={aplicador.id} value={aplicador.id}>
                                                {aplicador.num_ropo}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel>Equipo Aplicador</InputLabel>
                                    <Select
                                        label="Equipo Aplicador"
                                        onChange={(e) => setEquipoAplicadorId(e.target.value)}
                                    >
                                        {equipos.map((equipo) => (
                                            <MenuItem key={equipo.id} value={equipo.id}>
                                                {equipo.num_reganip ? `Número REGANIP: ${equipo.num_reganip}` :
                                                    equipo.num_roma ? `Número ROMA: ${equipo.num_roma}` :
                                                        `ID Aplicador: ${equipo.id_aplicador}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => createIdentificadorEmpresa(aplicadorEmpresaId, equipoAplicadorId)}
                                    disabled={loading}
                                    margin="normal"
                                >
                                    Crear Identificador Empresa
                                </Button>
                            </Grid>
                        </Paper>
                    </Modal>

                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" disabled={loading}>
                            Crear Tratamiento
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </Paper>
    );
}

export default TratamPostCosechaNew;

import React, { useState, useEffect } from "react";
import { api } from "../login/OAuth"

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography
} from "@mui/material";


function ListRegistros() {
    const [registros, setRegistros] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchRegistros();
    }, []);


    const fetchRegistros = async () => {
        try {
            const result = await api().get("/registros/list");
            setRegistros(result.data);
        } catch (error) {
            console.error("Error fetching registros:", error);
            setError(`Error: ${JSON.parse(error.request.response).detail}`);
        }
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="h6" gutterBottom>Nombre</Typography>

                        </TableCell>
                        <TableCell>
                            <Typography variant="h6" gutterBottom>Resultado</Typography>

                        </TableCell>
                        <TableCell>
                            <Typography variant="h6" gutterBottom>Fecha</Typography>

                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {registros.map(registro => (
                        <TableRow key={registro.id}>
                            <TableCell component="th" scope="row">
                                {registro.nombre}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {registro.resultado}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {registro.fecha}
                            </TableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </TableContainer>
    )
}
export default ListRegistros;
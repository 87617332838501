import React, { useState, useEffect, useCallback } from "react";
import { useParams } from 'react-router-dom';
import { api } from "../../login/OAuth";
import MateriasActivasInput from "./MateriasActivasInput";
import { Typography, Box, Checkbox, Grid, Button, MenuItem, FormControl, FormControlLabel, Select, InputLabel, TableContainer, TableHead, TableCell, TableRow, TableBody, Paper, Table, TextField, List, ListItem, ListItemText, Autocomplete, Chip } from "@mui/material";


const TratamFitoEditProductoFito = () => {
    const { id } = useParams();
    const [tratam, setTratam] = useState(null);

    const [cultivos, setCultivos] = useState([]);
    const [selectedCultivo, setSelectedCultivo] = useState(null);

    const [cultivosNoComunes, setCultivosNoComunes] = useState([]);
    const [selectedCultivoNoComun, setSelectedCultivoNoComun] = useState(null);

    const [productos, setProductos] = useState([]);
    const [selectedProducto, setSelectedProducto] = useState(null);

    const [productoInfo, setProductoInfo] = useState(null);
    const [abierto, setAbierto] = useState(false);

    const [selectedUso, setSelectedUso] = useState(null);

    const [unidades, setUnidades] = useState(null);
    const [selectedUnidad, setSelectedUnidad] = useState(null);

    const [initialProductoData] = useState({
        tipo_producto: null,
        num_registro: null,
        id_uso:null,
        dosis: null,
        cantidad: null,
        unidad: null,
        materia_activa: [],
    });
    const [selectedField, setSelectedField] = useState("dosis");
    const [productoData, setProductoData] = useState({ ...initialProductoData });
    const [error, setError] = useState(null);

    const handleFieldChange = (e) => {
        setSelectedField(e.target.value);
    };

    const fetchTratam = useCallback(async () => {
        try {
            const result = await api().get(`/tratam_fitos/${id}`);
            setTratam(result.data);
            if (result.data.producto_fito) {
                console.log(result.data.producto_fito)
                setProductoData(result.data.producto_fito);
            }
        } catch (error) {
            console.error("Error fetching tratamiento:", error);
        }
    }, [id]);

    useEffect(() => {

        api().get('/catalogos/productos_cultivo')
            .then(response => {
                setCultivos(response.data);
            })
            .catch(error => {
                console.error('Error al obtener la lista de cultivos', error);
            });

        const fetchUnidades = async () => {
            try {
                const result = await api().get(`/catalogos/unidades`);
                setUnidades(result.data);

            } catch (error) {
                console.error("Error fetching unidades:", error);
            }
        };


        fetchTratam();
        fetchUnidades();
        fetchProductosFitoNC();
        fetchNoComunes();
    }, [id, fetchTratam]);

    const fetchProductosFito = async () => {
        try {
            const result = await api().get(`/productos_fito/${selectedCultivo.producto_siex}/num_registro`);
            if (result.data.length == 0) {
                setAbierto(true);
                setProductos([]);
                setSelectedProducto(null);

            } else {
                setSelectedProducto(null);
                setProductos(result.data);
                setAbierto(false);
            }

        } catch (error) {
            console.error("Error fetching productos_fito:", error);
        }
    };

    const fetchDetallesProductoUso = async () => {
        try {
            let selectedCultivoValue = selectedCultivoNoComun ? selectedCultivoNoComun : selectedCultivo.producto_siex;
            const result = await api().get(`/productos_fito/${selectedCultivoValue}/${selectedProducto.numero_registro}`);
            setProductoInfo(result.data);
            console.log(result)
        } catch (error) {
            console.error("Error fetching productos_fito:", error);
        }
    };

    const fetchProductosFitoNC = async () => {
        try {
            const result = await api().get(`/productos_fito/${selectedCultivoNoComun}/productos`);
            setProductos(result.data);

        } catch (error) {
            console.error("Error fetching productos_fito:", error);
        }
    };

    const fetchNoComunes = async () => {
        try {
            const result = await api().get(`/cultivos_no_comunes`);
            setCultivosNoComunes(result.data);

        } catch (error) {
            console.error("Error fetching productos_fito:", error);
        }
    };

    useEffect(() => {
        if (selectedCultivo) {
            setSelectedCultivoNoComun(null);
            fetchProductosFito();
            setProductoData({ ...productoData, tipo_producto: selectedCultivo.codigo });

        }
    }, [selectedCultivo]);

    useEffect(() => {
        if (selectedCultivoNoComun) {
            fetchProductosFitoNC();
        }
    }, [selectedCultivoNoComun]);

    useEffect(() => {
        if (selectedCultivo == null) {
            setAbierto(false);
            setProductos([]);
            setSelectedProducto(null);
            setProductoInfo(null);
            setSelectedCultivoNoComun(null);
            setProductoData({ ...initialProductoData });
            setSelectedUnidad(null);
            setError(null);
            setSelectedUso(null);
        }
    }, [selectedCultivo]);

    useEffect(() => {
        if (selectedProducto) {
            fetchDetallesProductoUso();
            setProductoData({ ...productoData, num_registro: selectedProducto.numero_registro });
        }
    }, [selectedProducto]);

    const handleChangeUso = (uso) => {
        setSelectedUso(uso);
        setProductoData({ ...productoData, id_uso: uso});
    };

    const handleInputChange = (e, action = 'add') => {
        const { name, value } = e.target;
        const newValue = (name === 'dosis') || (name === 'cantidad') ? parseFloat(value) : value;
        const sanitizedValue = isNaN(newValue) ? null : newValue;
        setProductoData({ ...productoData, [name]: sanitizedValue });
    };

    const handleUnidad = (event, newValue) => {
        if (newValue) {
            setSelectedUnidad(newValue);
            setProductoData({ ...productoData, unidad: newValue.codigo_siex });
        }
    };

    const handleAddMateriaActiva = (materiaActiva) => {
        const materiaActivaInt = parseInt(materiaActiva, 10);
        if (!isNaN(materiaActivaInt) && materiaActivaInt.toString().length === 5) {
            setProductoData({
                ...productoData,
                materia_activa: [...productoData.materia_activa, materiaActivaInt],
            });
        } else {
            alert("Ingrese un número de 5 dígitos válido.");
        }
    };
    const handleDeleteMateriaActiva = (index) => {
        const updatedMateriasActivas = [...productoData.materia_activa];
        updatedMateriasActivas.splice(index, 1);
        setProductoData({ ...productoData, materia_activa: updatedMateriasActivas });
    };

    const handleAddProducto = async () => {
        try {
            if (selectedUso == null) {
                setError("Seleccione un uso");
                return;
            }
            if (!productoData[selectedField] || !selectedUnidad) {
                setError('Por favor, rellena todos los campos requeridos.');
                return;
            }
                console.log(selectedUso)
                const result = await api().patch(`/tratam_fitos/${id}/producto_fito/new`, productoData);
                if (result.status === 201) {
                    alert("Producto fito añadido correctamente");
                    fetchTratam();
                    setSelectedCultivo(null);

                } else {
                    setError(`Error ${result.status}: ${result.data.detail}`);
                    console.log(result)
                }
        } catch (error) {
            setError(`Error: ${(error.response.data.detail)}`);
            console.log(error)

        }
    };

    const handleDeleteProducto = async (productoId) => {
        try {
            console.log(productoId)
            const result = await api().delete(`/tratam_fitos/${id}/producto_fito/${productoId}/delete`);

            if (result.status === 204) {
                alert("Producto fito eliminado correctamente");
                fetchTratam();
                setError(null);

            } else {
                setError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setError(`Error: ${error.message}`);

        }
    };


    return (
        <Box marginLeft="3rem" marginTop="2rem">

            <Grid container spacing={2} justify={"center"}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Añadir producto fitosanitario</Typography>

                </Grid>
                <Grid item xs={12}>
                    {cultivos && (
                        <Autocomplete
                            options={cultivos}
                            getOptionLabel={(cultivo) => cultivo.producto_siex}
                            style={{ width: 400 }}
                            onChange={(event, newValue) => setSelectedCultivo(newValue)}
                            renderInput={(params) => (
                                <TextField {...params} label="Selecciona un cultivo" />
                            )}
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    {cultivosNoComunes && abierto && (
                        <>
                            <Typography variant="body1" paragraph>
                                El cultivo seleccionado no coincide con ningún uso fitosanitario. Selecciona un cultivo similar.
                            </Typography>
                            <Autocomplete
                                options={cultivosNoComunes}
                                getOptionLabel={(cultivo) => cultivo}
                                style={{ width: 400, marginTop: 20 }}
                                onChange={(event, newValue) => setSelectedCultivoNoComun(newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Selecciona un cultivo similar" />
                                )}
                            />
                        </>
                    )}
                </Grid>
                <Grid item xs={12}>

                    {selectedCultivo && productos && (
                        <>
                            <Autocomplete
                                options={productos}
                                getOptionLabel={(producto) => producto.numero_registro + ' ' + producto.nombre}
                                style={{ width: 400, marginTop: 20 }}
                                onChange={(event, newValue) => setSelectedProducto(newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Selecciona un producto" />

                                )}
                            />

                        </>
                    )}
                </Grid>
                {selectedProducto && productoInfo && (
                    <>
                        <Box margin="2rem">
                            <TableContainer component={Paper}>
                                <Typography variant="h5" component="h5" gutterBottom>
                                    Producto fitosanitario
                                </Typography>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>SIEX ID</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Nombre</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Estado</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Nº Registro</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Formulado</Typography>

                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={productoInfo.id}>
                                            <TableCell component="th" scope="row">
                                                {productoInfo.siex_id}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {productoInfo.nombre}
                                            </TableCell>
                                            <TableCell>
                                                {productoInfo.siex_estado}

                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {productoInfo.numero_registro}

                                            </TableCell>
                                            <TableCell>
                                                {productoInfo.formulado}
                                            </TableCell>
                                        </TableRow>


                                    </TableBody>
                                </Table>
                                <br />

                                <Typography variant="h5" component="h5" gutterBottom>
                                    Usos
                                </Typography>

                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Cultivo</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Agentes</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Dosis MIN</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Dosis MAX</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Ud. Dosis</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Volumen MIN</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Volumen MAX</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Ud. Volumen</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Aplicaciones</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Intervalo de aplicaciones</Typography>

                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6" gutterBottom>Seleccionar</Typography>

                                            </TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {productoInfo.usos.map(uso => (

                                            <TableRow key={uso.id}>
                                                <TableCell component="th" scope="row">
                                                    {uso.cultivo || "No especificado"}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {uso.agente || "No especificado"}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {uso.dosis_min || "No especificado"}

                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {uso.dosis_max || "No especificado"}

                                                </TableCell>
                                                <TableCell component="th" scope="row">

                                                    {uso.unidad_dosis || "No especificado"}
                                                </TableCell>
                                                <TableCell component="th" scope="row">

                                                    {uso.vol_min || "No especificado"}
                                                </TableCell>
                                                <TableCell component="th" scope="row">

                                                    {uso.vol_max || "No especificado"}
                                                </TableCell>
                                                <TableCell component="th" scope="row">

                                                    {uso.unidad_vol || "No especificado"}
                                                </TableCell>
                                                <TableCell component="th" scope="row">

                                                    {uso.aplicaciones || "No especificado"}
                                                </TableCell>
                                                <TableCell component="th" scope="row">

                                                    {uso.intervalo_aplicaciones || "No especificado"}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <FormControlLabel
                                                        key={uso.id}
                                                        control={
                                                            <Checkbox
                                                                checked={selectedUso === uso.id}
                                                                onChange={() => handleChangeUso(uso.id)}
                                                            />
                                                        }
                                                    />
                                                </TableCell>

                                            </TableRow>

                                        ))}
                                    </TableBody>
                                </Table>

                            </TableContainer>
                        </Box>

                        <br />
                        <Grid item xs={12}>

                            <FormControl fullWidth>
                                <InputLabel id="select-field-label">Seleccionar Campo</InputLabel>
                                <Select
                                    labelId="select-field-label"
                                    id="select-field"
                                    value={selectedField}
                                    onChange={handleFieldChange}
                                >
                                    <MenuItem value="dosis">Dosis</MenuItem>
                                    <MenuItem value="cantidad">Cantidad</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name={selectedField}
                                value={productoData[selectedField]}
                                label="Introduce dosis o cantidad"
                                onChange={handleInputChange}
                                margin="normal"
                                type="number"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                options={unidades}
                                getOptionLabel={(option) => option.unidades_medida?.toString() || ""}
                                value={selectedUnidad}
                                onChange={handleUnidad}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Unidad"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MateriasActivasInput onAddMateriaActiva={handleAddMateriaActiva} />
                        </Grid>

                        <Grid item xs={12}>
                            <div>
                                <Typography variant="h6" gutterBottom>Materias activas añadidas</Typography>
                                <div>
                                    {productoData.materia_activa.map((materiaActiva, index) => (
                                        <Chip
                                            key={index}
                                            label={materiaActiva}
                                            style={{ marginRight: 8, marginBottom: 8 }}
                                            onDelete={() => handleDeleteMateriaActiva(index)}
                                        />
                                    ))}
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddProducto}
                            >
                                Crear Producto Fito
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
            {tratam && tratam.productos_fito && (
                <div>
                    <br></br>
                    <br></br>
                    <Typography variant="h6" gutterBottom>
                        Productos fitosanitarios ya añadidos
                    </Typography>

                    <List>
                        {tratam.productos_fito.map((producto, index) => (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={`Número de registro del producto: ${producto.num_registro}`}
                                    secondary={`ID Producto cultivado: ${producto.tipo_producto}`}
                                    style={{ marginRight: '100px' }}
                                />
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleDeleteProducto(producto.id)}
                                    style={{ marginRight: '900px' }}

                                >
                                    Eliminar
                                </Button>
                            </ListItem>
                        ))}
                    </List>
                </div>
            )}
        </Box>

    );
};

export default TratamFitoEditProductoFito;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from "../../login/OAuth";
import { TextField, Button, Typography, Grid, Input, InputLabel } from '@mui/material';

function PastoreoEdit() {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    id_ajena_pastoreo: 0,
    fecha_inicio: '',
    fecha_fin: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPastoreo = async () => {
      try {
        const result = await api().get(`/pastoreos/${id}`);
        setFormData(result.data);
      } catch (error) {
        console.error("Error fetching pastoreo:", error);
        setError("Error al cargar el pastoreo");
      }
    };

    fetchPastoreo();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const result = await api().patch(`/pastoreos/${id}/update`, formData);
      if (result.status < 300) {
        alert('Pastoreo actualizado correctamente');
        setError(null);
      } else {
        setError(`Error ${result.status}: ${result.data.detail}`);
      }
    } catch (error) {
      setError(`Error: ${JSON.parse(error.request.response).detail}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container justifyContent="center">

      <Grid item xs={12} sm={8} md={6} lg={4}>

        <Typography variant="h5" gutterBottom>
          Actualizar Pastoreo
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="ID Ajena Pastoreo"
            type="number"
            name="id_ajena_pastoreo"
            value={formData.id_ajena_pastoreo}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
          />

          <InputLabel htmlFor="fecha-inicio">Fecha Inicio</InputLabel>
          <Input
            id="fecha-inicio"
            type="date"
            name="fecha_inicio"
            value={formData.fecha_inicio}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
            style={{ marginBottom: '10px' }}
          />
          <br></br>
          <InputLabel htmlFor="fecha-fin">Fecha Fin</InputLabel>
          <Input
            id="fecha-fin"
            type="date"
            name="fecha_fin"
            value={formData.fecha_fin}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            style={{ marginBottom: '10px' }}
          />
          <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
            Actualizar Pastoreo
          </Button>
        </form>
        {error && (
          <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
            {error}
          </Typography>
        )}
      </Grid>
    </Grid>

  );
}

export default PastoreoEdit;

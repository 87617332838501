import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { api } from "../../login/OAuth"
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";


function TratamFito() {
    const [tratams, setTratams] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        api()
            .get(`/cuadernos/${id}/tratams_fito`)
            .then((response) => {
                if (response.data.status_code) {
                } else {
                    setTratams(response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [id]);

    const handleOpenDeleteDialog = (itemId) => {
        setDeleteItemId(itemId);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteItem = () => {
        api().delete(`/tratam_fito/${deleteItemId}/delete`)
            .then((response) => {
                console.log('Elemento eliminado con éxito');
                setTratams((prevTratams) => prevTratams.filter(tratam => tratam.id !== deleteItemId));
                handleCloseDeleteDialog();
            })
            .catch((error) => {
                console.error('Error al eliminar el elemento', error);
            });
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <br></br>
                <Link to={`/cuadernos/${id}/tratam_fito/new`} style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="primary">
                        Crear tratamiento fitosanitario
                    </Button>
                </Link>
            </Grid>

            {tratams.length > 0 && (
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>IdAjenaTratamFito</TableCell>
                                    <TableCell>Fecha Inicio</TableCell>
                                    <TableCell>Fecha Fin</TableCell>
                                    <TableCell>Fecha Seca</TableCell>
                                    <TableCell>Observaciones</TableCell>
                                    <TableCell>Eficacia</TableCell>
                                    <TableCell>DGCs</TableCell>
                                    <TableCell>Problematica</TableCell>
                                    <TableCell>Justificaciones</TableCell>
                                    <TableCell>Otras actuaciones fito</TableCell>
                                    <TableCell>Productos fito</TableCell>
                                    <TableCell>Identificadores aplicadores</TableCell>
                                    <TableCell>Asesor</TableCell>
                                    <TableCell>Editar</TableCell>
                                    <TableCell>Eliminar</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tratams.map((tratam) => (
                                    <TableRow key={tratam.id_ajena_tratam_fito}>
                                        <TableCell>{tratam.id_ajena_tratam_fito}</TableCell>
                                        <TableCell>{new Date(tratam.fecha_inicio).toLocaleString()}</TableCell>
                                        <TableCell>{new Date(tratam.fecha_fin).toLocaleString()}</TableCell>
                                        <TableCell>{tratam.fecha_seca ? tratam.fecha_seca : "Sin fecha de seca"}</TableCell>
                                        <TableCell>{tratam.observaciones}</TableCell>
                                        <TableCell>{tratam.eficacia}</TableCell>
                                        <TableCell>
                                            <Link to={`/tratam_fito/${tratam.id}/edit/dgcs`} style={{ textDecoration: 'none' }}>
                                            <Button variant="contained" color="primary">
                                                    Editar
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/tratam_fito/${tratam.id}/edit/problematica`} style={{ textDecoration: 'none' }}>
                                            <Button variant="contained" color="primary">
                                                    Editar
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/tratam_fito/${tratam.id}/edit/justificaciones`} style={{ textDecoration: 'none' }}>
                                            <Button variant="contained" color="primary">
                                                    Editar
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/tratam_fito/${tratam.id}/edit/otras_act`} style={{ textDecoration: 'none' }}>
                                            <Button variant="contained" color="primary">
                                                    Editar
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/tratam_fito/${tratam.id}/edit/producto_fito`} style={{ textDecoration: 'none' }}>
                                            <Button variant="contained" color="primary">
                                                    Editar
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/tratam_fito/${tratam.id}/edit/identificadores`} style={{ textDecoration: 'none' }}>
                                            <Button variant="contained" color="primary">
                                                    Editar
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/tratam_fito/${tratam.id}/edit/asesor`} style={{ textDecoration: 'none' }}>
                                            <Button variant="contained" color="primary">
                                                    Editar
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Link to={`/tratam_fito/${tratam.id}/edit/`} style={{ textDecoration: 'none' }}>
                                                <Button variant="contained" color="primary">
                                                    Editar
                                                </Button>
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleOpenDeleteDialog(tratam.id)} variant="contained" color="error">
                                                Eliminar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}

            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>¿Estás seguro de eliminar este tratamiento post cosecha?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteItem} color="error">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
export default TratamFito;
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { api } from '../login/OAuth';
import { Container, Box,Typography, CircularProgress } from "@mui/material";
import CardItem from '../../components/CardItem';


function ActividadesExplotacion() {
    const location = useLocation();
    const cuaderno = location.state && location.state.cuaderno;

    const [loading, setLoading] = useState(false);

    const createDatos = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);

            const result = await api().post(`/actividades_explotacion/new?cuaderno_id=${cuaderno.id_cuaderno}`);

            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    alert(`Error: ${result.data.detail}`);
                } else {
                    alert("Actividad explotacion creada correctamente");

                }
            } else {
                alert(`Error ${result.data.detail}`);
            }
        } catch (error) {
            alert("Error", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            {cuaderno.actividades_explotacion != null ? (
                <div>
                    <CardItem
                        src='/images/fitosanitarios.png'
                        text='Crear actividad agraria'
                        label='Actividad'
                        path='/actividades_agrarias/new'
                    />
                    <CardItem
                        src='/images/fitosanitarios.png'
                        text='Crear tratamiento post cosecha'
                        label='Tratamiento'
                        path= {`/cuadernos/${cuaderno.id_cuaderno}/tratamientos_post_cosecha`}
                    />
                    <CardItem
                        src='/images/fitosanitarios.png'
                        text='Crear tratamiento edif'
                        label='Tratamiento'
                        path='/tratamientos_edif_instalaciones/new'
                    />
                    <CardItem
                        src='/images/fitosanitarios.png'
                        text='Crear tratamiento fitosanitario'
                        label='Tratamiento'
                        path={`/cuadernos/${cuaderno.id_cuaderno}/tratamientos_fito`}
                    />
                    <CardItem
                        src='/images/fitosanitarios.png'
                        text='Crear siembra plantacion'
                        label='Siembra'
                        path='/siembra_plantaciones/new'
                    />
                    <CardItem
                        src='/images/fitosanitarios.png'
                        text='Crear uso semilla tratada'
                        label='Uso semilla'
                        path='/usos_semillas_tratadas/new'
                    />
                    <CardItem
                        src='/images/fitosanitarios.png'
                        text='Crear cosecha'
                        label='Cosechas'
                        path='/cosechas/new'
                    />
                    <CardItem
                        src='/images/fitosanitarios.png'
                        text='Crear fertilizacion'
                        label='Fertilizaciones'
                        path='/fertilizaciones/new'
                    />
                    <CardItem
                        src='/images/fitosanitarios.png'
                        text='Crear riego'
                        label='Riegos'
                        path='/riegos/new'
                    />
                    <CardItem
                        src='/images/fitosanitarios.png'
                        text='Crear comercialización'
                        label='Comercialización'
                        path='/comercializaciones/new'
                    />
                    <CardItem
                        src='/images/fitosanitarios.png'
                        text='Crear analítica'
                        label='Analíticas'
                        path='/analiticas/new'
                    />
                    <CardItem
                        src='/images/fitosanitarios.png'
                        text='Crear pastoreos'
                        label='Pastoreos'
                        path='/pastoreos/new'
                    />
                    <CardItem
                        src='/images/fitosanitarios.png'
                        text='Crear datos cubierta'
                        label='Datos cubierta'
                        path='/datos_cubiertas/new'
                    />
                </div>
            ) : (

                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Crea unos datos de explotacion para el cuaderno con id {cuaderno.id_cuaderno}
                    </Typography>
                    <Box component="form" noValidate onSubmit={createDatos} sx={{ mt: 3 }}>
                        <Button
                            toLink="/"
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={loading}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {loading ? <CircularProgress size={24} /> : "Crear"}
                        </Button>
                    </Box>
                </Box>
            )}
        </Container>
    );
}

export default ActividadesExplotacion